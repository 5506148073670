#company-driver {
  #driver-menu-row {
    margin: 0 0 12px 0;
    #driver-menu {
      background-color: var(--airbag-white);
      border-radius: 6px;
      padding: 12px 6px 0 6px;
      overflow: auto;
      white-space: nowrap;
      #menu-container {
        .sec {
          padding: 3px 18px 6px 18px;
          font-size: 12px;
          color: var(--airbag-grey);
          cursor: pointer;
          font-weight: bold;
          display: inline-block;
          margin: 0 0 12px 0;
          transition: 0.2s;
          border-bottom-color: var(--airbag-primary);
          &:hover {
            .sec-content {
              color: #000;
              border-bottom: 2px solid var(--airbag-primary);
            }
          }
          &:not(:last-child) {
            border-right: 1px solid var(--airbag-grey-light);
          }
          .sec-content {
            padding: 3px 3px 6px 3px;
          }
          &.active {
            .sec-content {
              color: var(--airbag-dark);
              border-bottom: 2px solid var(--airbag-primary);
            }
            .sect-icon {
              stroke: var(--airbag-dark);
            }
          }
          .sect-icon {
            margin: 0 5px -3px 0;
            width: 15px;
            stroke: var(--airbag-grey);
          }
        }
      }
    }
  }
  #actions {
    button {
      @media only screen and (max-width: 600px) {
        margin: 10px auto;
        width: 100%;
      }
      @media only screen and (min-width: 601px) {
        margin: 10px 10px 10px 0;
      }
    }
  }
}
