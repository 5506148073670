#_loader-inner_ {
  width: 100%;
  margin: 25px 0;
  .preloader-wrapper {
    margin: 0 auto;
    .spinner-layer {
      border-color: #464646;
    }
  }
}
