div#_forgot-pass_ {
  .outer {
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--airbag-background);
    .middle {
      display: table-cell;
      vertical-align: middle;
      .inner {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: 400px;
        #formContainer {
          .row {
            margin-top: -30px;
            display: flex;
            flex-flow: column;
            justify-content: center;
            width: 100%;
            #logo-container {
              text-align: center;
              #logoBlack {
                width: 100%;
                max-width: 242px;
              }
            }
            #formTitle {
              font-size: 22px;
              font-weight: bold;
              padding-left: 22px;
              margin-bottom: 0;
            }
            #formSubtitle {
              font-size: 14px;
              padding-left: 22px;
              margin: 6px 0 30px 0;
            }
            input {
              background-color: var(--airbag-grey-lighter);
              &#password {
                padding-right: 36px !important;
              }
            }
            .eye-icon {
              position: absolute;
              margin: 12px 0 0 -30px;
              cursor: pointer;
              stroke: 2px;
              &.text {
                stroke: var(--airbag-grey);
              }
              &.password {
                stroke: var(--airbag-dark);
              }
            }
            button {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
