#_trip-info_ {
  #driver-name {
    margin: 40px 0 24px 0;
    font-family: var(--airbag-font);
    line-height: 1.5;
    a {
      color: inherit;
      font-weight: bold;
      padding: 3px 6px;
      border-bottom: 2px solid var(--airbag-primary);
    }
  }
  #extra-info {
    text-align: right;
    margin: 0 0 12px 0;
    svg {
      stroke-width: 25px;
      background-color: var(--airbag-primary);
      cursor: pointer;
      color: var(--airbag-dark);
      padding: 10px;
      border-radius: 35px;
      width: 35px;
      height: 35px;
      text-align: center;
      vertical-align: middle;
      line-height: 35px;
    }
  }
  #general-info {
    background-color: var(--airbag-white);
    text-align: center;
    margin: 0 0 12px 0;
    border-radius: 6px;
    padding: 10px 0;
    .col {
      padding: 10px 5px;
      @media only screen and (max-width: 450px) {
        min-height: 85px;
      }
    }
    .title {
      font-size: 18px;
      margin: 0;
      font-weight: bold;
      .title-icon {
        margin: 0 10px -2px 0;
        stroke: var(--airbag-dark-light);
        width: 18px;
      }
      .title-fa-icon {
        font-size: 14px;
        margin-right: 10px;
        color: var(--airbag-dark-light);
      }
    }
    #time-icon {
      margin-bottom: -5px;
      stroke-width: 30px;
      stroke: var(--airbag-dark-light);
    }
    #velocimeter-icon {
      margin-bottom: -5px;
      stroke-width: 30px;
      stroke: var(--airbag-dark-light);
    }
    .subtitle {
      font-size: 12px;
      margin: 0;
      color: var(--airbag-dark-light);
    }
  }
  #start-end-points {
    list-style: none;
    margin: 0 0 12px 0;
    padding: 11px;
    background-color: var(--airbag-white);
    border-radius: 6px;
    li {
      padding: 8px 0;
      font-size: 15px;
      svg {
        float: left;
        margin-top: 3px;
        width: 20px;
        &.small-icon {
          font-size: 10px;
          color: var(--airbag-grey-light);
        }
        &.big-icon {
          font-size: 15px;
          color: var(--airbag-primary);
          stroke: var(--airbag-primary);
          stroke-width: 18px;
        }
      }
      .point-content {
        padding-left: 30px;
        p {
          color: var(--airbag-grey);
          margin: 0 0 0 0;
          &.point-label {
            font-weight: normal;
            font-size: 14px;
            color: var(--airbag-dark);
            margin: 0;
          }
        }
      }
    }
  }
  .metrics-table {
    background-color: var(--airbag-white);
    padding: 15px 0;
    border-radius: 6px;
    text-align: center;
    margin: 0 0 12px 0;
    .table-title {
      font-weight: bold;
      margin: 0;
      @media only screen and (max-width: 992px) {
        margin: 10px 0 5px 0;
      }
      @media only screen and (min-width: 993px) {
        margin: 0;
      }
    }
    .wrapp {
      @media only screen and (max-width: 992px) {
        margin: 10px 0;
      }
      .stat-title {
        color: var(--airbag-grey);
        @media only screen and (max-width: 992px) {
          margin: 15px 0 0 0;
        }
        @media only screen and (min-width: 993px) {
          font-size: 13px;
          margin: 30px 0 0 0;
        }
        &.less-margin {
          @media only screen and (max-width: 992px) {
            margin: 15px 0 0 0;
          }
          @media only screen and (min-width: 993px) {
            margin: 20px 0 0 0;
          }
        }
      }
      .stat-value {
        color: var(--airbag-grey);
        margin: 0;
        margin-top: 2px;
        font-weight: bold;
        @media only screen and (max-width: 600px) {
          font-size: 17px;
        }
        @media only screen and (min-width: 601px) {
          font-size: 18px;
        }
        &.st-red {
          color: var(--airbag-error);
        }
        &.st-yellow {
          color: var(--airbag-attention);
        }
        &.st-green {
          color: var(--airbag-successful);
        }
        &.st-na {
          color: var(--airbag-grey-light);
        }
        span {
          font-weight: normal;
          font-size: 14px;
          color: var(--airbag-grey);
        }
      }
    }
  }
  .collapsible {
    margin: 0 0 12px 0;
    border: 0;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 0;
    .collapsible-header {
      display: block;
      background-color: transparent;
      border: 0;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      .tips-title {
        font-weight: bold;
      }
      .caret-icon {
        margin-left: 9px;
      }
      .header-icon {
        margin: 0 9px;
      }
    }
    .collapsible-body {
      background-color: transparent;
      border: 0;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
      padding: 0 20px 20px 20px;
      .tip {
        white-space: pre-line;
        font-size: 14px;
        margin: 2px 0;
      }
      #driving-tips-btn {
        margin-top: 15px;
        text-align: right;
      }
    }
  }
  .detailed-feedback-row {
    margin-bottom: 20px;
    .df-title {
      font-weight: bold;
      margin: 0 0 5px 0;
    }
    .incident {
      margin-bottom: 10px;
      p {
        margin: 0;
      }
      .incident-desc {
        font-size: 12px;
        p {
          span {
            font-weight: bold;
          }
        }
      }
    }
  }
  #heartbeats {
    #hb-subtitle {
      margin-bottom: 0;
      text-align: right;
    }
    #hb-title {
      text-align: center;
    }
    .hb-container {
      span {
        margin: 5px 10px 5px 0px;
        padding: 2px 15px;
        font-size: 12px;
        border-radius: 40px;
        font-weight: bold;
        display: inline-block;
        &.no-info {
          background-color: var(--airbag-grey-light);
          color: var(--airbag-dark);
        }
        &.ok {
          background-color: var(--airbag-successful-light);
          color: var(--airbag-successful);
        }
        &.not-ok {
          background-color: var(--airbag-error-light);
          color: var(--airbag-error);
        }
        &.attention {
          background-color: var(--airbag-attention-light);
          color: var(--airbag-attention);
        }
      }
    }
  }
}
