div#_ranking_ {
  #main-title {
    margin-bottom: 10px;
    h5 {
      text-align: left;
      font-weight: bold;
      font-size: 18px;
    }
    #more-info {
      cursor: pointer;
      color: var(--airbag-primary);
      margin-left: 5px;
      font-size: 12px;
    }
  }
  #filters {
    margin: 18px 0 18px 0;
    .filter-input {
      display: inline-block;
      .ranking-month-filter {
        width: 100%;
        cursor: pointer;
        border-radius: 40px !important;
        border: 1px solid transparent !important;
        box-sizing: border-box !important;
        padding: 12px !important;
      }
    }
    #range-filter {
      @media only screen and (max-width: 600px) {
        padding: 21px 36px 21px 27px;
      }
      @media only screen and (min-width: 601px) and (max-width: 992px) {
        padding: 0 25px;
      }
      @media only screen and (min-width: 993px) {
        padding: 0 40px 0 40px;
      }
      .input-range__slider {
        background: var(--airbag-primary);
        border-color: var(--airbag-primary);
      }
      .input-range__track--active {
        background: var(--airbag-primary);
      }
      .input-range__label-container {
        font-family: 'PP Object Sans';
        font-size: 11px;
      }
      .input-range__label--min, .input-range__label--max {
        display: none;
      }
      #minMaxKmLabel {
        margin: 0 0 15px 0;
        display: inline-block;
        width: 100%;
        text-align: center;
      }
    }
  }
  #loader-container {
    margin-top: 30px;
  }
  #no-data {
    text-align: center;
    margin-top: 30px;
    font-weight: 700;
    #error-icon {
      width: 100px;
      stroke: var(--airbag-grey-light);
    }
    p {
      margin-top: 0;
    }
  }
  #ranking-info {
    background-color: var(--airbag-white);
    padding: 18px 0;
    border-radius: 6px;
    text-align: center;
    margin: 0;
    .col {
      padding: 6px;
    }
    .title {
      font-size: 16px;
      margin: 0;
      font-weight: bold;
      svg {
        margin-right: 10px;
        width: 18px;
        margin-bottom: -3px;
        stroke: var(--airbag-grey);
      }
      &.bad {
        color: var(--airbag-error);
      }
      &.regular {
        color: var(--airbag-attention);
      }
      &.good {
        color: var(--airbag-successful);
      }
    }
    .subtitle {
      font-size: 12px;
      margin: 0;
      color: var(--airbag-grey);
    }
  }
  #ranking-table {
    margin-top: 12px;
    .ranking-row {
      &:hover {
        background-color: var(--airbag-background);
        a {
          border-bottom: 1px solid var(--airbag-primary);
        }
      }
      a {
        color: inherit;
      }
      .pos-diff {
        width: 60px;
        display: inline-block;
        text-align: center;
        svg {
          font-size: 20px;
          width: 20px;
          vertical-align: middle;
          &.up {
            color: var(--airbag-successful);
          }
          &.down {
            color: var(--airbag-error);
          }
        }
        .jump {
          display: inline-block;
          width: 20px;
          vertical-align: middle;
          text-align: center;
          font-size: 12px;
          color: var(--airbag-grey);
        }
      }
      .pos-index {
        margin-right: 5px;
        color: var(--airbag-grey);
      }
      .score {
        font-weight: bold;
        &.bad {
          color: var(--airbag-error);
        }
        &.regular {
          color: var(--airbag-attention);
        }
        &.good {
          color: var(--airbag-successful);
        }
      }
    }
  }
}
