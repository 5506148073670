#_company-groups_ {
  #no-data {
    text-align: center;
    margin-top: 25px;
    font-weight: 700;
    #error-icon {
      width: 100px;
      stroke: var(--airbag-grey-light);
    }
    p {
      margin-top: 0;
    }
  }
  #actions {
    margin-bottom: 0;
    input {
      background-color: var(--airbag-white);
      width: 100%;
      max-width: 200px;
      padding-right: 28px !important;
    }
    #add-group-btn {
      float: right;
      #add-group-icon {
        stroke: var(--airbag-dark);
        stroke-width: 25px;
        margin: -4px 0 -4px 10px;
        width: 16px;
      }
    }
  }
  #groups-table {
    margin-top: 18px;
    .group-row {
      &:hover {
        background-color: var(--airbag-background);
        a {
          border-bottom: 1px solid var(--airbag-primary);
        }
      }
      a {
        color: inherit;
      }
      .pos-index {
        margin-right: 5px;
        color: var(--airbag-grey-light);
      }
    }
  }
}
