#_profile-menu-wrapper_ {
  margin-top: 15px;
  float: right;
  #menu-preview {
    cursor: pointer;
    margin-right: 15px;
    div {
      display: inline-block;
      p {
        margin: 0;
        color: var(--airbag-white);
      }
      .u-name {
        font-size: 14px;
      }
      .u-email {
        font-size: 12px;
        font-weight: lighter;
      }
      #caret {
        margin: 0 0 9px 12px;
        color: var(--airbag-white);
      }
    }
  }
  #dropdown-menu-wrapper {
    #dropdown-menu {
      box-sizing: border-box;
      max-height: 500px;
      height: auto;
      background: var(--airbag-white);
      box-shadow: 0px 6px 15px -4px rgba(222, 222, 222, 1);
      top: 42px;
      right: 11px;
      overflow-y: auto;
      width: 200px;
      position: absolute;
      border-radius: 6px;
      .row-item {
        padding: 12px 12px;
        border-top: 1px solid var(--airbag-background);
        cursor: pointer;
        &:hover {
          background-color: var(--airbag-background);
        }
        p {
          margin: 0;
        }
        #logout {
          color: var(--airbag-error);
        }
        .menu-icon {
          margin-right: 6px;
          font-size: 12px;
        }
      }
    }
  }
}
