.weather-table {
  background-color: #fff;
  border-radius: 6px;
  margin-left: 1px !important;
  margin-right: 1px !important;
  text-align: center;
  .table-title {
    font-weight: bold;
    margin-top: 20px !important;
    @media only screen and (max-width: 992px) {
      margin: 10px 0 5px 0;
    }
    @media only screen and (min-width: 993px) {
      margin: 0;
    }
  }
  #cloudIcon {
    float: right;
    margin-right: 10px;
    margin-bottom: -18px;
    stroke-width: 25px;
  }
  .tableHeader {
    display: inline-flex;
    justify-content: center;
  }
  .address {
    font-size: 12px;
    margin: 10px 0 0 0;
    min-height: 40px;
    color: #696969;
  }
  .subtitle {
    padding-top: 20px;
    margin: 0;
    color: #6b6b6b;
    font-size: 12px;
  }
  .titleIcons {
    margin-right: 10px;
  }
  .weatherStat {
    font-weight: bold;
    margin-top: 6px;
  }
}
