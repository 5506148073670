div#_company-game_ {
  .g-card {
    margin: 0 0 12px 0;
    background-color: var(--airbag-white);
    border-radius: 6px;
  }
  .airbag-table-container {
    margin-bottom: 12px;
    background-color: #fff;
    height: 305px;
    .airbag-top {
      .date-filter, .select-filter {
        float: right;
        input, select {
          margin-top: 6px !important;
          width: 120px;
          height: 30px;
          font-size: 14px;
          background-color: var(--airbag-grey-lighter);
        }
        select {
          margin-right: 9px !important;
          width: 100px;
        }
      }
    }
    .table-row {
      &:hover {
        background-color: var(--airbag-background);
        a {
          border-bottom: 1px solid var(--airbag-primary);
        }
      }
      a {
        color: inherit;
      }
      .pos-index {
        margin-right: 5px;
        color: var(--airbag-grey-light);
      }
      .kpi-span {
        min-width: 70px;
        display: inline-block;
      }
    }
  }
  .no-data {
    color: var(--airbag-grey-light);
    margin-top: 30px;
    font-size: 30px;
  }
  #profile-card {
    padding: 12px;
    #profile-coins {
      float: right;
      text-align: center;
      border: 1px solid var(--airbag-grey);
      padding: 3px 18px;
      border-radius: 20px;
      font-size: 14px;
      svg {
        margin-right: 9px;
      }
    }
    #photo {
      text-align: center;
      margin-top: 12px;
      img {
        width: 100%;
        max-width: 70px;
      }
    }
    #name {
      text-align: center;
      font-weight: bold;
      margin-top: 0;
    }
    .divider-g {
      border-top: 1px solid var(--airbag-grey-lighter);
      margin: 9px 0;
      display: block;
    }
    tr {
      border: 0;
      font-size: 12px;
      td {
        padding: 6px 0;
      }
    }
    #edit-link {
      text-align: center;
      margin: 30px 0 15px 0;
      button {
        width: 100%;
      }
    }
  }
  #top-kpis {
    margin: 0;
    .col {
      padding: 0;
    }
    .top-card {
      background-color: var(--airbag-white);
      border-radius: 6px;
      text-align: center;
      margin: 0 0 12px 0;
      .inner-top-card {
        padding: 24px 6px;
        svg {
          margin: 0 12px -9px 0;
          padding: 10px;
          border-radius: 60px;
          width: 39px;
          height: 39px;
          &#au {
            background-color: var(--airbag-blue-light);
            color: var(--airbag-blue);
          }
          &#ad {
            background-color: var(--airbag-successful-light);
            color: var(--airbag-successful);
          }
          &#nd {
            background-color: var(--airbag-attention-light);
            color: var(--airbag-attention);
          }
        }
        span {
          font-size: 21px;
          font-weight: bold;
        }
        p {
          margin: 12px 0 0 0;
          font-size: 14px;
          color: var(--airbag-grey);
        }
      }
    }
  }
  #active-drivers-card {
    #ad-top {
      margin-bottom: 30px;
    }
    @media only screen and (max-width: 600px) {
      .select-filter, .date-filter {
        width: 100%;
        select, input {
          width: 100%;
        }
      }
    }
    #ad-range-filter {
      float: right;
      margin-top: -8px;
      @media only screen and (max-width: 600px) {
        padding: 0 25px;
        width: 100%;
        margin: 9px 0 15px 0;
      }
      @media only screen and (min-width: 601px) and (max-width: 1150px) {
        padding: 0 30px;
        width: 100%;
        margin: 9px 0 15px 0;
      }
      @media only screen and (min-width: 1151px) {
        padding: 0 30px;
        min-width: 220px;
      }
      label {
        margin: 0 0 21px 0;
        font-size: 10px;
        display: inline-block;
        width: 100%;
        text-align: center;
      }
      .input-range__slider {
        background: var(--airbag-primary);
        border-color: var(--airbag-primary);
      }
      .input-range__track--active {
        background: var(--airbag-primary);
      }
      .input-range__label-container {
        font-family: 'PP Object Sans';
        font-size: 11px;
      }
      .input-range__label--min, .input-range__label--max {
        display: none;
      }
    }
    #active-drivers-table {
      progress[value] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        width: 200px;
        margin: 0 10px;
        border-radius: 10em;
        background: var(--airbag-grey-lighter);
      }
      progress[value]::-webkit-progress-bar {
        border-radius: 10em;
        background: var(--airbag-grey-lighter);
      }
      progress[value]::-webkit-progress-value {
        border-radius: 10em;
        background: var(--airbag-blue);
      }
      progress[value]::-moz-progress-bar {
        border-radius: 10em;
        background: var(--airbag-blue);
      }
    }
  }
  #risk-drivers-cards {
    .comment-icon {
      margin-right: 6px;
      font-size: 11px;
      color: var(--airbag-error);
    }
    .overall-score {
      margin: 0 6px;
      display: inline-block;
      width: 25px;
    }
    .score-item {
      margin: 0 3px;
      cursor: pointer;
    }
    .scores {
      font-weight: bold;
      text-align: center;
      .na {
        color: var(--airbag-grey-light);
      }
      .bad {
        color: var(--airbag-error);
      }
      .regular {
        color: var(--airbag-attention);
      }
      .good {
        color: var(--airbag-successful);
      }
      .overall-score {
        margin: 0 6px;
        display: inline-block;
        width: 25px;
      }
      .scores-icons {
        margin-left: 6px;
        .score-item {
          margin: 0 3px;
        }
      }
    }
  }
  #coins-history-card {
    #coins-history-table {
      .add {
        color: var(--airbag-successful);
      }
      .subtract {
        color: var(--airbag-error);
      }
    }
  }
}
