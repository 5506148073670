#_vehicle-individual-scores_ {
  margin: 0;
  #scores-content {
    a {
      color: inherit;
    }
    .metrics-table {
      text-align: center;
      .table-title {
        font-weight: bold;
        margin: 0;
        @media only screen and (max-width: 992px) {
          margin: 10px 0 5px 0;
        }
        @media only screen and (min-width: 993px) {
          margin: 0;
        }
      }
      .wrapp {
        @media only screen and (max-width: 992px) {
          margin: 10px 0;
        }
        .stat-title {
          color: var(--airbag-grey);
          @media only screen and (max-width: 992px) {
            margin: 15px 0 0 0;
          }
          @media only screen and (min-width: 993px) {
            font-size: 13px;
            margin: 30px 0 0 0;
          }
          &.less-margin {
            @media only screen and (max-width: 992px) {
              margin: 15px 0 0 0;
            }
            @media only screen and (min-width: 993px) {
              margin: 20px 0 0 0;
            }
          }
        }
        .stat-value {
          color: var(--airbag-grey);
          margin: 0;
          margin-top: 2px;
          font-weight: bold;
          @media only screen and (max-width: 600px) {
            font-size: 17px;
          }
          @media only screen and (min-width: 601px) {
            font-size: 18px;
          }
          &.st-red {
            color: var(--airbag-error);
          }
          &.st-yellow {
            color: var(--airbag-attention);
          }
          &.st-green {
            color: var(--airbag-successful);
          }
          &.st-na {
            color: var(--airbag-grey-light);
          }
          span {
            font-weight: normal;
            font-size: 14px;
            color: var(--airbag-grey);
          }
        }
      }
    }
    .driverRow {
      margin: 20px 2px 0 25px;
      font-size: 15px;
      .driverIcon {
        margin-right: 10px;
        color: var(--airbag-grey-light);
      }
    }
    #preloader-wrapper {
      margin: 20px 0;
      #preloader {
        margin: 15px auto 0 auto;
        .spinner-layer {
          border-color: var(--airbag-primary);
        }
      }
    }
  }
}
