div#_activities-list_ {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  #no-data {
    margin-top: 0px;
  }
  .activity-row {
    background-color: #fff;
    padding: 10px;
    border-radius: 6px;
    width: 100%;
    margin: 0 auto 15px auto;
    .pos {
      margin-right: 5px;
      color: #a7a7a7;
    }
    .activity-info {
      p {
        margin: 0;
      }
      .act-tag {
        font-weight: bold;
        margin: 10px 10px 10px 0;
        border-radius: 50px;
        padding: 8px 10px 6px 10px;
        font-size: 11px;
        min-height: 35px;
        display: inline-flex;
        align-items: center;
        .act-icon {
          width: 15px;
          max-height: 16px;
          margin: 0 5px 2px 0;
        }
      }
      .desc {
        .ok {
          font-weight: bold;
          color: var(--airbag-successful);
        }
        .not-ok {
          font-weight: bold;
          color: var(--airbag-error);
        }
      }
    }
    .cta-filters {
      text-align: right;
      .cta-icon {
        font-size: 21px;
        cursor: pointer;
        background-color: var(--airbag-primary);
        padding: 8px;
        width: 30px;
        height: 30px;
        border-radius: 50px;
        margin-left: 9px;
      }
    }
    .activity-photos {
      background-color: #f2f2f2;
      border-radius: 6px;
      padding: 4px 10px 14px 10px;
      margin-top: 10px;
      .arrow {
        background-color: #ffffff;
        padding: 6px;
        font-size: 30px;
        border-radius: 20px;
        width: 30px;
        height: 30px;
      }
      .act-photo {
        width: 100%;
        max-width: 250px;
      }
    }
    .audio-wrapper {
      width: 100%;
      text-align: center;
      margin-top: 12px;
    }
  }
}
