div#_trips_ {
  #loader-container {
    margin-top: 25px;
  }
  #no-data {
    text-align: center;
    margin-top: 25px;
    font-weight: 700;
    #error-icon {
      width: 100px;
      stroke: var(--airbag-grey-light);
    }
    p {
      margin-top: 0;
    }
  }
  #trips-table {
    margin: 12px 0;
    background-color: var(--airbag-white);
    #trip-scores-exp {
      .score-item {
        margin: 0 3px;
        cursor: pointer;
      }
    }
    .trip-score {
      font-weight: bold;
      text-align: center;
      .bad {
        color: var(--airbag-error);
      }
      .regular {
        color: var(--airbag-attention);
      }
      .good {
        color: var(--airbag-successful);
      }
      .overall-score {
        margin: 0 6px;
        display: inline-block;
        width: 25px;
      }
      .scores-icons {
        margin-left: 6px;
        .score-item {
          margin: 0 3px;
        }
      }
    }
    .trip-row {
      &:hover {
        background-color: var(--airbag-background);
        a {
          border-bottom: 1px solid var(--airbag-primary);
        }
      }
      a {
        color: inherit;
      }
    }
    .date-row {
      font-weight: bold;
    }
  }
}
