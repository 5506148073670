div#_restore-password_ {
  .outer {
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--airbag-background);
    .middle {
      display: table-cell;
      vertical-align: middle;
      .inner {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: 400px;
        #formContainer {
          .row {
            margin-top: -30px;
            display: flex;
            flex-flow: column;
            justify-content: center;
            width: 100%;
            #logo-container {
              text-align: center;
              #logoBlack {
                width: 100%;
                max-width: 242px;
              }
            }
            #formTitle {
              font-size: 22px;
              font-weight: bold;
              padding-left: 22px;
            }
            input {
              background-color: var(--airbag-grey-lighter);
              &#password {
                padding-right: 36px !important;
              }
            }
            .eye-icon {
              position: absolute;
              margin: 12px 0 0 -30px;
              cursor: pointer;
              stroke: 2px;
              &.text {
                stroke: var(--airbag-grey);
              }
              &.password {
                stroke: var(--airbag-dark);
              }
            }
            ul {
              text-align: left;
              margin: 0 0 30px 12px;
              li {
                font-size: 12px;
                color: #535353;
                margin: 2px 0;
                .spot {
                  width: 9px;
                  height: 9px;
                  border-radius: 9px;
                  display: inline-block;
                  background-color: #ddd;
                  margin: 0 6px 0 0;
                  &.valid {
                    background-color: var(--airbag-successful);
                  }
                  &.not-valid {
                    background-color: var(--airbag-error);
                  }
                }
                #spec-char {
                  margin: 0 0 0 12px;
                  font-weight: bold;
                  font-family: monospace;
                  letter-spacing: 1px;
                  @media only screen and (max-width: 600px) {
                    font-size: 11px;
                  }
                }
              }
            }
            button {
              width: 100%;
            }
            #terms-text {
              font-size: 11px;
              margin: 12px 0 0 0;
              a {
                color: #000;
                font-weight: bold;
                border-bottom: 1px solid #000;
              }
            }
          }
        }
      }
    }
  }
}
