#company-main {
  background-color: var(--airbag-background);
  #company-content {
    min-height: 100vh;
    padding: 90px 12px 0 12px;
    display: flex;
    justify-content: center;
    @media only screen and (max-width: 992px) {
      margin: 0 auto !important;
    }
    @media only screen and (min-width: 993px) {
      margin: 0 auto 0 58px;
    }
    #section-container {
      width: 100%;
      max-width: 1200px;
    }
  }
}
