div#_trip-analytics_ {
  .charts-container {
    background-color: var(--airbag-white);
    border-radius: 6px;
    margin: 0 0 12px 0;
    #timeline-charts {
      padding: 5px 15px;
      .title {
        text-align: center;
        font-weight: bold;
        margin-bottom: 10px;
        font-size: 14px;
      }
      #main-labels {
        text-align: center;
        .dot-span {
          margin: 0 10px;
          .dot {
            width: 10px;
            height: 10px;
            border-radius: 10px;
            display: inline-block;
            margin-right: 2px;
            &.grey-dot {
              background-color: var(--airbag-grey-light);
            }
            &.red-dot {
              background-color: var(--airbag-error-light);
            }
          }
        }
      }
      .t-row {
        margin-bottom: 20px;
        .subtitle {
          margin: 0 0 2px 0;
          font-weight: bold;
          font-size: 13px;
        }
        .t-container {
          width: 100%;
          .block {
            height: 30px;
            float: left;
            &.pointer {
              cursor: pointer;
            }
          }
        }
        .labels {
          .start,
          .end {
            font-size: 10px;
          }
          .start {
            float: left;
          }
          .end {
            float: right;
          }
        }
        .clear {
          clear: both;
        }
      }
    }
    #speed-comparison-chart {
      height: 400px;
      padding: 10px 20px 20px 20px;
    }
    #altimetry-chart {
      height: 350px;
      padding: 10px 20px 20px 20px;
    }
    #doughnut-chart {
      width: 100%;
      max-width: 400px;
      margin: 20px auto;
    }
    #scores-polar,
    #ecoscores-polar {
      width: 100%;
      margin: 20px auto;
    }
    #scores-polar {
      max-width: 450px;
    }
    #ecoscores-polar {
      max-width: 400px;
    }
  }
}
