div#_driver-metrics_ {
  #no-scores {
    text-align: center;
    svg {
      color: var(--airbag-primary);
    }
  }
  .metrics-table {
    background-color: #fff;
    padding: 15px 0;
    border-radius: 6px;
    text-align: center;
    margin: 0 0 12px 0;
    .table-title {
      font-weight: bold;
      margin: 0;
      @media only screen and (max-width: 992px) {
        margin: 10px 0 5px 0;
      }
      @media only screen and (min-width: 993px) {
        margin: 0;
      }
    }
    .wrapp {
      @media only screen and (max-width: 992px) {
        margin: 10px 0;
      }
      .stat-title {
        color: var(--airbag-dark-light);
        @media only screen and (max-width: 992px) {
          margin: 15px 0 0 0;
        }
        @media only screen and (min-width: 993px) {
          font-size: 13px;
          margin: 30px 0 0 0;
        }
        &.less-margin {
          @media only screen and (max-width: 992px) {
            margin: 15px 0 0 0;
          }
          @media only screen and (min-width: 993px) {
            margin: 20px 0 0 0;
          }
        }
      }
      .stat-value {
        color: var(--airbag-dark-light);
        margin: 0;
        margin-top: 2px;
        font-weight: bold;
        @media only screen and (max-width: 600px) {
          font-size: 17px;
        }
        @media only screen and (min-width: 601px) {
          font-size: 18px;
        }
        &.st-red {
          color: var(--airbag-error);
        }
        &.st-yellow {
          color: var(--airbag-attention);
        }
        &.st-green {
          color: var(--airbag-successful);
        }
        &.st-na {
          color: var(--airbag-grey-light);
        }
        span {
          font-weight: normal;
          font-size: 14px;
          color: var(--airbag-grey);
        }
      }
    }
  }
  .box-container {
    margin: 25px 0;
    .box-icon {
      stroke: var(--airbag-grey);
      margin-bottom: -6px;
    }
  }
  .graph-box {
    background-color: var(--airbag-white);
    border-radius: 6px;
    padding: 10px 20px;
    margin: 0 0 12px 0;
  }
  .scores-data {
    height: 300px;
  }
  #driving-times {
    height: 300px;
  }
  #location-graphs {
    background-color: #fff;
    border-radius: 6px;
    #filters {
      margin-top: 6px !important;
      margin-right: 6px !important;
      width: 100%;
      max-width: 200px;
      float: right;
      select {
        background-color: #f8f8f8;
      }
    }
    .risk-by-location {
      height: 400px;
      margin-bottom: 60px !important;
    }
  }
  .coins-graphs {
    height: 340px;
  }
}
