div#_usage-reports_ {
  .airbag-table-container {
    margin-bottom: 12px;
  }
  #loader-container {
    margin-top: 25px;
  }
  .row {
    margin-bottom: 0px;
  }
  #open-add-report {
    margin-bottom: 12px;
    #add-report-icon {
      stroke: var(--airbag-dark);
      stroke-width: 25px;
      margin: -4px 0 -4px 10px;
      width: 16px;
    }
  }
  #add-report-btn {
    margin: 12px 0px 12px;
    padding-bottom: 12px;
    @media only screen and (max-width: 400px) {
      width: 100%;
    }
    #add-report-icon {
      stroke: #181818;
      stroke-width: 25px;
      margin: -4px 0 -4px 10px;
      width: 16px;
    }
  }
  #no-data {
    text-align: center;
    margin-top: 25px;
    font-weight: 700;
    #error-icon {
      width: 100px;
      stroke: var(--airbag-grey-light);
    }
    p {
      margin-top: 0;
    }
  }
  .closeModalContainer {
    width: 100%;
    #close-modal-button {
      float: right;
      margin: 12px 24px 0 0;
      cursor: pointer;
    }
  }
  .inputReport {
    background-color: #f2f2f2;
  }
  .newReportButton {
    margin-top: 10px;
  }
  #reports-table {
    margin-top: 0px;
    .report-row {
      &:hover {
        background-color: var(--airbag-background);
      }
      .pos-index {
        margin-right: 5px;
        color: var(--airbag-grey-light);
      }
      .download-cta-wrapper {
        text-align: center;
      }
      .download-icon {
        cursor: pointer;
        color: var(--airbag-primary);
        font-size: 18px;
      }
      .close-icon {
        cursor: pointer;
        color: var(--airbag-error);
        font-size: 18px;
      }
    }
  }
  #newReportForm {
    overflow: hidden;
    h4 {
      font-weight: 600;
      font-size: x-large;
    }
    .reportFormContainer {
      margin-left: 24px;
    }
    #exp {
      color: var(--airbag-grey);
      font-size: 12px;
    }
  }
}
