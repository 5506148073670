div#_trips-in-risk-table_ {
  #trips-in-risk-table {
    margin-bottom: 12px;
    background-color: var(--airbag-white);
    height: 100%;
    height: 250px;
    a {
      color: inherit;
    }
    .trip-score {
      font-weight: bold;
      &.bad {
        color: var(--airbag-error);
      }
      &.regular {
        color: var(--airbag-attention);
      }
      &.good {
        color: var(--airbag-successful);
      }
    }
    .trip-row {
      &:hover {
        background-color: var(--airbag-background);
        a {
          border-bottom: 1px solid var(--airbag-primary);
        }
      }
      a {
        color: inherit;
      }
    }
  }
}
