div#_driver-heartbeats_ {
  .hb-container {
    span {
      margin: 5px 10px 5px 0px;
      padding: 2px 15px;
      font-size: 12px;
      border-radius: 40px;
      font-weight: bold;
      display: inline-block;
      &.no-info {
        background-color: var(--airbag-grey-light);
        color: var(--airbag-dark);
      }
      &.ok {
        background-color: var(--airbag-successful-light);
        color: var(--airbag-successful);
      }
      &.not-ok {
        background-color: var(--airbag-error-light);
        color: var(--airbag-error);
      }
      &.attention {
        background-color: var(--airbag-attention-light);
        color: var(--airbag-attention);
      }
    }
  }
}
