div#_two-FA-wizard_ {
	padding: 0 18px;
	#logo-container {
		text-align: center;
		#logoBlack {
			width: 100%;
			max-width: 242px;
		}
	}
	#formTitle {
		font-size: 22px;
		font-weight: bold;
		margin-bottom: 6px;
	}
	p {
		margin: 0 0 18px 0;
	}
	input, select {
		width: 100%;
		margin: 0 0 18px 0 !important;
		display: inline-block;
		background-color: var(--airbag-grey-lighter);
	}
	button {
		width: 100%;
	}
	#counter {
		text-align: right;
		#timer-span {
			font-weight: bold;
			width: 25px;
			display: inline-block;
			text-align: center;
		}
		#request-new {
			cursor: pointer;
			padding-bottom: 3px;
			border-bottom: 2px solid var(--airbag-primary);
		}
	}
}