div#_driver-info_ {
  #info {
    .section-wrapper {
      margin: 0 0 12px 0;
      .section {
        background-color: var(--airbag-white);
        padding: 10px;
        border-radius: 6px;
        width: 100%;
        margin: 0 auto;
        .section-label {
          font-weight: bold;
          margin-bottom: 10px;
          &.center-mobile {
            @media only screen and (max-width: 600px) {
              text-align: center;
            }
          }
          #tz {
            font-weight: normal;
            font-size: 11px;
            color: #737373;
          }
          .edit {
            float: right;
            color: var(--airbag-primary);
            stroke: var(--airbag-primary);
            stroke-width: 20px;
            cursor: pointer;
          }
        }
        .section-content {
          padding: 0;
          margin-bottom: 0;
          min-height: 60px;
          label {
            margin-left: 35px;
          }
          p {
            margin-top: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            a {
              color: inherit;
            }
            .indicator {
              color: var(--airbag-grey-light);
              margin-right: 10px;
              width: 25px;
            }
            #history {
              stroke: var(--airbag-grey-light);
              margin-right: 10px;
              width: 20px;
              margin-bottom: -3px;
              stroke-width: 25px;
            }
          }
          &.schedule-tags {
            div {
              padding: 10px 5px 10px 5px;
              margin-bottom: 10px;
              max-width: 100%;
              overflow: auto;
              span {
                font-size: 12px;
                margin: 0 5px 0 0;
                padding: 3px 12px;
                font-weight: bold;
                border-radius: 50px;
                &.activation {
                  background-color: var(--airbag-successful-light);
                  color: var(--airbag-successful);
                }
                &.deactivation {
                  background-color: var(--airbag-error-light);
                  color: var(--airbag-error);
                }
                &.no-action {
                  background-color: var(--airbag-background);
                  color: var(--airbag-dark);
                }
              }
            }
          }
        }
        .ba-graphs-wrapper {
          width: 100%;
          max-width: 500px;
          margin: 0 auto;
          min-height: 250px;
          @media only screen and (max-width: 992px) {
            margin: 10px auto;
          }
        }
        .flag-row {
          margin: 12px 0;
          svg {
            cursor: pointer;
            color: var(--airbag-primary);
            margin-right: 6px;
            font-size: 11px;
          }
          span {
            margin-left: 6px;
            font-weight: bold;
          }
          .ok {
            color: var(--airbag-successful);
          }
          .notok {
            color: var(--airbag-error);
          }
        }
      }
    }
    #actions {
      text-align: right;
      button {
        margin: 0 6px;
      }
    }
  }
}
