div#_company-map_ {
  margin: -20px -12px 0 -12px;
  #map-container {
    width: 100%;
    height: calc(100vh - 70px);
    z-index: 1 !important;
    position: relative;
    margin-bottom: 0;
    #top-elements {
      width: 100%;
      margin: 6px 0 0 6px;
      padding: 8px;
      font-size: 12px;
      z-index: 1;
      position: absolute;
      @media only screen and (max-width: 600px) {
        font-size: 9px;
        padding: 8px 8px 8px 0;
      }
      #info-tags {
        .info-tag {
          margin: 0 6px 12px 0;
          padding: 4px 15px;
          border-radius: 40px;
          font-weight: bold;
          display: inline-block;
          background-color: var(--airbag-white);
          border: 2px solid var(--airbag-white);
          &.bad {
            background-color: var(--airbag-error-light);
            color: var(--airbag-error);
          }
          &.regular {
            background-color: var(--airbag-attention-light);
            color: var(--airbag-attention);
          }
          &.good {
            background-color: var(--airbag-successful-light);
            color: var(--airbag-successful);
          }
        }
      }
      #group-filter {
        width: 100%;
        max-width: 200px;
        label {
          color: var(--airbag-dark);
        }
      }
    }
  }
}
