div#_ranking-table_ {
  #ranking-table {
    margin-bottom: 25px;
    overflow: auto;
    max-height: 250px;
    background-color: var(--airbag-white);
    .score {
      font-weight: bold;
      text-align: center;
      &.bad {
        color: var(--airbag-error);
      }
      &.regular {
        color: var(--airbag-attention);
      }
      &.good {
        color: var(--airbag-successful);
      }
    }
    .ranking-row {
      &:hover {
        background-color: var(--airbag-background);
        a {
          border-bottom: 1px solid var(--airbag-primary);
        }
      }
      a {
        color: inherit;
      }
      .pos-index {
        margin-right: 5px;
        color: var(--airbag-grey-light);
      }
    }
  }
}
