#footer {
  background-color: var(--airbag-dark-light);
  padding: 10px 5px;
  margin: 25px 0 0 0;
  @media only screen and (max-width: 600px) {
    padding: 30px 0;
  }
  @media only screen and (min-width: 601px) {
    padding: 45px 20px 30px 20px;
  }
  @media only screen and (min-width: 993px) {
    padding: 45px 20px 30px 58px;
  }
  #info {
    text-align: center;
    #logo-container {
      width: 100%;
      max-width: 150px;
      margin: 0 auto 12px auto;
      height: 90px;
      #logo {
        height: 100%;
      }
    }
    p {
      margin: 0 0 5px 0;
      color: #dedede;
    }
  }
  .links {
    @media only screen and (max-width: 767px) {
      margin: 25px 0 10px 0;
      text-align: center;
    }
    h5 {
      margin-top: 6px;
      font-weight: bold;
      color: #fff;
      text-transform: uppercase;
      letter-spacing: 1.5px;
    }
    ul {
      padding-left: 0;
      li {
        @media only screen and (max-width: 767px) {
          margin: 6px 0;
        }
        @media only screen and (min-width: 768px) {
          margin: 2px 0;
        }
        a {
          @media only screen and (max-width: 767px) {
            font-size: 15px;
          }
          @media only screen and (min-width: 768px) {
            font-size: 14px;
          }
          color: var(--airbag-grey-light);
          font-family: var(--airbag-font);
          cursor: pointer;
          transition: 0.3s;
          -webkit-transition: 0.3s;
          -moz-transition: 0.3s;
          -o-transition: 0.3s;
          -ms-transition: 0.3s;
          &:hover {
            color: var(--airbag-primary);
          }
        }
      }
    }
  }
  .small {
    font-family: var(--airbag-font);
    margin: 15px 0 0 0;
    font-size: 12px;
    color: var(--airbag-white);
  }
  #deltaprotect {
    width: 100%;
    max-width: 150px;
    margin-top: 15px;
  }
  #it {
    width: 100%;
    max-width: 200px;
  }
}
