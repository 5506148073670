div#_trip-map_ {
  margin-bottom: 12px;
  #map {
    width: 100%;
    height: 500px;
    margin-bottom: 0;
    margin: 12px auto 0 auto;
    canvas: {
      border-radius: 6px;
    }
  }
}
