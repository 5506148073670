div#_activities_ {
	#loader-container {
    margin-top: 25px;
  }
  #no-data {
    text-align: center;
    margin-top: 25px;
    font-weight: 700;
    #error-icon {
      width: 100px;
      stroke: var(--airbag-grey-light);
    }
    p {
      margin-top: 0;
    }
  }
	#activities-table {
    margin: 12px 0;
    .activity-row {
      &:hover {
        background-color: var(--airbag-background);
        a {
          border-bottom: 1px solid var(--airbag-primary);
        }
        .no-underline-link {
          border-bottom: 0;
        }
      }
      a {
        color: inherit;
      }
      .act-tag {
        font-weight: bold;
        border-radius: 50px;
        padding: 6px 10px 3px 10px;
        font-size: 11px;
        display: inline-flex;
        align-items: center;
        margin-right: 12px;
        .act-icon {
          width: 15px;
          max-height: 16px;
          margin: 0 5px 2px 0;
        }
      }
      .media-indicator {
        vertical-align: middle;
        margin: -6px 12px 0 0;
        display: inline-block;
        svg {
          margin-right: 3px;
        }
      }
    }
    .date-row {
      font-weight: bold;
    }
  }
}