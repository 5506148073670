div#_vehicle_ {
  .action-icon {
    background-color: var(--airbag-primary);
    stroke: var(--airbag-grey);
    cursor: pointer;
    padding: 4px;
    border-radius: 35px;
    width: 35px;
    height: 35px;
    text-align: center;
    vertical-align: middle;
    line-height: 35px;
  }
  #main-t {
    text-align: center;
    margin: 5px 0 25px 0;
    font-weight: bold;
  }
  #map {
    width: 100%;
    margin-bottom: 24px;
  }
  #vehicle-menu {
    background-color: var(--airbag-white);
    border-radius: 6px;
    padding: 12px 6px 0 6px;
    overflow: auto;
    white-space: nowrap;
    margin-bottom: 12px;
    #menu-container {
      .sec {
        padding: 3px 18px 6px 18px;
        font-size: 12px;
        color: var(--airbag-grey);
        cursor: pointer;
        font-weight: bold;
        display: inline-block;
        margin: 0 0 12px 0;
        transition: 0.2s;
        border-bottom-color: var(--airbag-primary);
        &:hover {
          .sec-content {
            color: #000;
            border-bottom: 2px solid var(--airbag-primary);
          }
        }
        &:not(:last-child) {
          border-right: 1px solid var(--airbag-grey-light);
        }
        .sec-content {
          padding: 3px 3px 6px 3px;
        }
        &.active {
          .sec-content {
            color: var(--airbag-dark);
            border-bottom: 2px solid var(--airbag-primary);
          }
          #beta {
            color: var(--airbag-dark);
          }
          .sect-icon {
            stroke: var(--airbag-dark);
          }
        }
        #beta {
          font-size: 9px;
          font-weight: lighter;
        }
        .sect-icon {
          margin: 0 5px -3px 0;
          width: 15px;
          stroke: var(--airbag-grey);
        }
      }
    }
  }
  .info-table {
    background-color: var(--airbag-white);
    border-radius: 6px;
    margin-left: 1px !important;
    margin-right: 1px !important;
    margin-bottom: 24px;
    text-align: center;
    padding: 12px 0;
    .table-title {
      font-weight: bold;
      margin: 12px 0 5px 0;
    }
    .subtitle {
      padding-top: 18px;
      margin: 0;
      color: var(--airbag-grey);
      font-size: 12px;
    }
    .tableValue {
      margin: 0 0 12px 0;
      #driver-link {
        color: #000;
        border-bottom: 1px solid var(--airbag-primary);
      }
    }
  }
}
