#_manual-video_ {
  #manuals-courtain {
    p {
      font-size: 50px;
    }
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: var(--airbag-dark);
    svg {
      right: 0;
      position: absolute;
      stroke: var(--airbag-dark);
      background-color: var(--airbag-primary);
      border-radius: 50px;
      width: 24px;
      padding: 4px;
      margin: 24px 24px 0 0;
      cursor: pointer;
    }
    #courtain-content {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      iframe {
        height: 480px;
        width: 90%;
        max-width: 720px;
      }
    }
  }
}
