div#_trip-log-filters_ {
  margin-top: 12px;
  #filter-btn {
    background-color: var(--airbag-primary);
    cursor: pointer;
    color: var(--airbag-dark);
    padding: 8px;
    border-radius: 35px;
    width: 35px;
    height: 35px;
    text-align: center;
    vertical-align: middle;
    line-height: 35px;
  }
  #trip-log-filters-container {
    text-align: right;
    span {
      cursor: pointer;
      font-family: var(--airbag-font);
      font-weight: bold;
      margin: 10px 6px;
      display: inline-block;
      border-radius: 50px;
      padding: 8px 10px 6px 10px;
      font-size: 11px;
      &.inactive-btn {
        opacity: 0.5;
        background-color: var(--airbag-grey-light) !important;
        color: var(--airbag-grey) !important;
      }
      svg {
        margin-right: 10px;
        font-size: 14px;
      }
    }
  }
}
