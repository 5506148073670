div#_add-driver_ {
  #main-t {
    text-align: center;
    margin-bottom: 25px;
    color: var(--airbag-primary);
  }
  form {
    .big-label {
      margin-bottom: 0;
      p {
        margin-bottom: 20px;
        font-weight: bold;
        color: var(--airbag-grey);
      }
    }
    .react-datepicker-wrapper {
      width: 100%;
    }
    input,
    select {
      background-color: var(--airbag-background);
    }
  }
}
