.no-scroll {
  overflow: hidden !important;
}
#company-sidenav {
  height: calc(100% - 70px);
  background-color: var(--airbag-white);
  position: fixed;
  z-index: 99;
  bottom: 0;
  overflow: hidden;
  box-shadow: 0px 6px 15px -4px rgba(222, 222, 222, 1);
  // transition: 0.2s;
  // -webkit-transition: 0.2s;
  // -moz-transition: 0.2s;
  // -o-transition: 0.2s;
  // -ms-transition: 0.2s;
  @media only screen and (max-width: 992px) {
    left: -210px;
    &.show-sidenav {
      left: 0;
    }
  }
  &.collapsed {
    width: 58px;
  }
  &.not-collapsed {
    width: 210px;
  }
  #sidenav-wrapper {
    height: 100%;
    position: relative;
    #sidenav-top {
      padding: 6px 6px 12px 6px;
      text-align: center;
      height: 150px;
      #logo-container {
        width: 100%;
        max-width: 150px;
        margin: 0 auto;
        height: 90px;
        #company-logo {
          height: 100%;
        }
      }
      #company-name {
        #company-group-select {
          background-color: var(--airbag-grey-lighter);
          display: block;
          margin: 0 auto 0 auto !important;
          width: 100%;
          max-width: 180px;
        }
        #c-name {
          font-size: 14px;
          font-weight: bold;
          margin: 6px 0 0 0;
        }
      }
      #sidenav-sam {
        &.small-sam {
          width: 40px;
        }
        &.big-sam {
          width: 60px;
        }
      }
    }
    #sidenav-inner {
      overflow: auto;
      height: calc(100% - 150px);
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
      #links {
        list-style-type: none;
        margin: 0 0 10px 0;
        width: 100%;
        padding: 0 10px;
        li {
          display: table;
          color: var(--airbag-dark);
          width: 100%;
          border-radius: 6px;
          margin: 2px 0;
          transition: 0.2s;
          -webkit-transition: 0.2s;
          -moz-transition: 0.2s;
          -o-transition: 0.2s;
          -ms-transition: 0.2s;
          @media only screen and (max-width: 992px) {
            height: 45px;
            font-size: 15px;
          }
          @media only screen and (min-width: 993px) {
            height: 34px;
            font-size: 13px;
          }
          &:hover {
            background-color: var(--airbag-grey-lighter);
          }
          &.divider-l {
            border-top: 1px solid var(--airbag-grey-lighter);
            height: 1px;
          }
          .icon {
            width: 18px;
            max-height: 18px;
            font-size: 14px;
            vertical-align: middle;
          }
          .active {
            background-color: var(--airbag-grey-lighter);
          }
          a,
          span {
            vertical-align: middle;
            display: table-cell;
            color: inherit;
            text-decoration: none;
            cursor: pointer;
            font-family: inherit;
            padding: 0 10px 0 10px;
            border-radius: 6px;
          }
          p {
            display: inline;
            vertical-align: middle;
            margin-left: 15px;
            padding: 0 4px 5px 2px;
            font-weight: 400;
            transition: 0.2s;
            -webkit-transition: 0.2s;
            -moz-transition: 0.2s;
            -o-transition: 0.2s;
            -ms-transition: 0.2s;
          }
        }
      }
    }
  }
}
#sidenav-courtain {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 19;
  display: none;
  background-color: rgba(0, 0, 0, 0.7);
  &.show-nc {
    display: block !important;
  }
}
#company-navbar {
  width: 100%;
  height: 70px;
  position: fixed;
  background-color: var(--airbag-dark-light);
  padding: 0 0 5px 0;
  z-index: 19;
  .burger {
    width: 25px;
    height: 20px;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    display: none;
    cursor: pointer;
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    .burger-span {
      background-color: var(--airbag-grey-light);
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
      &:nth-child(1) {
        top: 0px;
      }
      &:nth-child(2),
      &:nth-child(3) {
        top: 8px;
      }
      &:nth-child(4) {
        top: 16px;
      }
    }
  }
  .open {
    .burger-span {
      &:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
      &:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
      &:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
    }
  }
  #_notifications-bell-wrapper_ {
    margin-top: 18px;
    float: right;
    z-index: 100;
    #notif-bell-container{
      cursor: pointer;
      margin-right: 21px;
      div {
        display: inline-block;
        #notif-bell {
          width: 33px;
          height: 33px;
          color: var(--airbag-primary);
          padding: 8px;
          border-radius: 50%;
          margin: 0 0 -1px 0;
        }
        #has-unread {
          color: var(--airbag-error);
          margin-bottom: 20px;
          margin-left: -12px;
          font-size: 12px;
        }
      }
    }
  }
}
