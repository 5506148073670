div#_company-analytics_ {
  #main-title {
    margin-bottom: 10px;
    h5 {
      text-align: left;
      font-weight: bold;
      font-size: 18px;
    }
    p {
      .pointer {
        cursor: pointer;
        text-decoration: underline;
        text-decoration-color: var(--airbag-primary);
      }
      margin: 5px 0 0 0;
      width: 100%;
      text-align: justify;
      max-width: 600px;
      font-size: 14px;
      font-weight: 400;
    }
    #more-info {
      cursor: pointer;
      color: var(--airbag-primary);
      margin-left: 5px;
      font-size: 12px;
    }
  }
  #filters {
    .filter-input {
      width: 100%;
      margin: 0 6px 12px 0;
      display: inline-block;
      @media only screen and (min-width: 601px) {
        max-width: 200px;
      }
    }
    .month-filter {
      width: 100%;
      cursor: pointer;
      border-radius: 40px !important;
      border: 1px solid transparent !important;
      box-sizing: border-box !important;
      padding: 12px !important;
    }
  }
  #no-data {
    text-align: center;
    margin-top: 25px;
    font-weight: 700;
    #error-icon {
      width: 100px;
      stroke: var(--airbag-grey-light);
    }
    p {
      margin-top: 0;
    }
  }
  .indicator-title {
    margin: 0 6px 12px 6px;
    padding: 6px 0;
    font-size: 12px;
    border-bottom: 1px solid var(--airbag-grey-light);
  }
  .b-title {
    font-weight: 700;
    text-align: center;
    margin: 0 0 0 0;
  }
  .card-custom {
    background-color: var(--airbag-white);
    padding: 20px 0;
    border-radius: 6px;
    @media only screen and (max-width: 600px) {
      margin: 12px 0 18px 0;
    }
    @media only screen and (min-width: 601px) {
      margin: 0 0 12px 0;
    }
    &.main-card {
      padding: 12px 0 6px 0;
      .kpi-container {
        text-align: center;
        margin: 6px 0;
        padding-top: 6px;
        min-height: 55px;
        .kpi {
          margin: 0 0 2px 0;
          font-weight: bold;
          font-size: 14px;
          .kpi-icon {
            stroke: var(--airbag-grey);
            margin-right: 10px;
            margin: 0 6px -4px 0;
            width: 18px;
          }
          .change {
            margin-left: 6px;
            font-size: 12px;
            font-weight: normal;
            svg {
              margin-right: 3px;
              font-size: 10px;
            }
          }
        }
        label {
          display: block;
          font-size: 11px;
          margin: 0;
          color: var(--airbag-grey);
        }
      }
    }
    &.secondary-card {
      .description {
        padding-left: 25px;
        line-height: 1.8;
        margin-bottom: 0;
        font-size: 14px;
        .desc-value {
          font-weight: bold;
          color: #333;
          border-bottom: 2px solid var(--airbag-primary);
        }
        .desc-icon {
          color: var(--airbag-primary);
          margin: 0 10px 0 -25px;
        }
      }
      .delta-exp {
        margin: 0 0 0 8px;
        font-size: 12px;
        font-weight: normal;
        svg {
          margin-right: 5px;
          font-size: 10px;
        }
      }
    }
  }
  .graph-box {
    margin-bottom: 12px;
    background-color: var(--airbag-white);
    border-radius: 6px;
    padding: 6px 12px;
  }
  .change,
  .delta-exp {
    svg {
      &.decrease {
        color: var(--airbag-error);
      }
      &.increase {
        color: var(--airbag-successful);
      }
      &.no-change {
        color: var(--airbag-grey-light);
      }
    }
  }
  .graphs-container {
    width: 100%;
    margin: 0 auto 12px 0;
    background-color: var(--airbag-white);
    padding: 15px;
    border-radius: 6px;
    .custom-chart-title {
      font-weight: bold;
      text-align: center;
      @media only screen and (max-width: 600px) {
        margin: 30px 0 -42px 0;
      }
      @media only screen and (min-width: 601px) {
        margin: 30px 0 -24px 0;
      }
    }
    .doughnut-chart {
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
      @media only screen and (max-width: 992px) {
        margin: 15px auto;
      }
      .no-data {
        text-align: center;
        margin-top: 42px;
        font-size: 16px;
      }
    }
    .doughnut-chart-map {
      width: 100%;
      max-width: 380px;
      margin: 0 auto;
      @media only screen and (max-width: 992px) {
        margin: 15px auto;
      }
    }
  }
  #charts-not-available {
    text-align: center;
    #no-data-icon {
      font-size: 2em;
      color: var(--airbag-grey);
    }
    p {
      margin: 12px 0 0 0;
    }
  }
}
