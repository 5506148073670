div#_schedule-form_ {
  .big-label {
    margin-bottom: 0;
    .big-label-title {
      margin-bottom: 20px;
      font-weight: bold;
      color: #464646;
    }
  }
  .inline-error {
    font-size: 12px;
    color: #f93d3d;
    margin-left: 10px;
  }
  select {
    background-color: #f2f2f2;
  }
  .day-row {
    padding: 5px 0;
    margin-bottom: 6px;
    line-height: 40px;
    .day-label {
      margin-bottom: 6px;
      label {
        margin: 12px 0 0 0;
        span {
          color: #000;
        }
      }
    }
    .day-box-content {
      width: 100%;
      max-width: 400px;
      text-align: center;
      .action-index {
        margin-right: 5px;
        color: #a7a7a7;
      }
      .select-inputs {
        select {
          width: 100%;
          margin: 0 10px 10px 10px !important;
          display: inline-block;
          @media only screen and (max-width: 600px) {
            max-width: 120px;
          }
          @media only screen and (min-width: 601px) {
            max-width: 150px;
          }
        }
      }
      .delete-action {
        color: #f93d3d;
        cursor: pointer;
      }
      .add-action {
        background-color: var(--airbag-primary);
        cursor: pointer;
        color: #fff;
        padding: 6px;
        border-radius: 25px;
        width: 25px;
        height: 25px;
        text-align: center;
        vertical-align: middle;
        line-height: 25px;
      }
    }
  }
}
