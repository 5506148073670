div#_edit-schedule_ {
  margin-top: 25px;
  #main-t {
    text-align: center;
    margin-bottom: 25px;
    color: var(--airbag-primary);
  }
  #sub-btn {
    margin-top: 20px;
  }
}
