:root {
  /* Airbag Primary Colors */
  --airbag-og: #ffd500;
  --airbag-primary: #f6c700;
  --airbag-background: #f8f8f8;

  /* Airbag Secundary Colors */
  --airbag-dark: #181818;
  --airbag-dark-light: #343538;
  --airbag-grey: #797979;
  --airbag-grey-light: #dadada;
  --airbag-grey-lighter: #eeeeee;
  --airbag-white: #ffffff;
  --airbag-blue: #1baff2;
  --airbag-blue-light: #e1f6ff;

  /* Airbag Status Colors */
  --airbag-successful: #008e6c;
  --airbag-successful-light: #c3eddc;
  --airbag-attention: #ffb800;
  --airbag-attention-light: #fef1d0;
  --airbag-error: #c91316;
  --airbag-error-light: #ffdad6;

  /* Others */
  --airbag-font: PP Object Sans;
}
