#company-home {
  .main-title {
    margin: 12px 0 6px 0;
    h5 {
      font-size: 18px;
      margin: 0;
      span {
        font-weight: bold;
      }
    }
    p {
      margin: 4px 0 0 0;
      font-size: 14px;
    }
  }
  #filters {
    text-align: right;
    .item-container {
      display: inline-block;
      @media only screen and (max-width: 600px) {
        margin: 24px 0 6px 0;
        width: 100%;
      }
      @media only screen and (min-width: 601px) {
        margin: 0 0 0 10px;
        width: 200px;
      }
    }
  }
  .day-indicator-title {
    margin: 0 6px 12px 6px;
    padding: 6px 0;
    font-size: 12px;
    border-bottom: 1px solid var(--airbag-grey-light);
  }
  .graph-box {
    margin-bottom: 12px;
    background-color: var(--airbag-white);
    border-radius: 6px;
    padding: 6px 12px;
  }
  .graph-top {
    padding: 6px 17px 12px 6px;
    position: absolute;
    height: 50px;
    svg {
      cursor: pointer;
      color: var(--airbag-primary);
      margin-left: 3px;
      font-size: 14px;
    }
  }
  .graph-message {
    text-align: center;
    margin-top: 40px;
    .graph-icon-indicator {
      width: 50px;
      stroke: var(--airbag-grey);
    }
    p {
      margin: 0;
      font-size: 12px;
    }
  }
  .active-drivers-chart {
    .active-drivers-container {
      padding: 0 0 12px 0;
      margin-top: 40px;
      height: 198px;
      .no-home-data {
        text-align: center;
        .icon-indicator {
          width: 50px;
          stroke: #8d8d8d;
        }
        p {
          margin-top: 0;
        }
      }
    }
  }
}
