div#_company-groups_ {
  #main-title {
    text-align: center;
    font-weight: bold;
    margin-bottom: 25px;
    @media only screen and (max-width: 600px) {
      margin-top: 25px;
    }
  }
  #group-menu {
    margin-bottom: 12px;
    background-color: var(--airbag-white);
    border-radius: 6px;
    padding: 12px 6px 0 6px;
    overflow: auto;
    white-space: nowrap;
    #menu-container {
      .sec {
        padding: 3px 18px 6px 18px;
        font-size: 12px;
        color: #8d8d8d;
        cursor: pointer;
        font-weight: bold;
        display: inline-block;
        margin: 0 0 12px 0;
        transition: 0.2s;
        border-bottom-color: var(--airbag-primary);
        &:hover {
          .sec-content {
            color: #000;
            border-bottom: 2px solid var(--airbag-primary);
          }
        }
        &:not(:last-child) {
          border-right: 1px solid var(--airbag-grey-light);
        }
        .sec-content {
          padding: 3px 3px 6px 3px;
        }
        &.active {
          .sec-content {
            color: var(--airbag-dark);
            border-bottom: 2px solid var(--airbag-primary);
          }
          #beta {
            color: var(--airbag-dark);
          }
          .sect-icon {
            stroke: var(--airbag-dark);
          }
        }
        #beta {
          font-size: 9px;
          font-weight: lighter;
        }
        .sect-icon {
          margin: 0 5px -3px 0;
          width: 15px;
          stroke: var(--airbag-dark-light);
        }
      }
    }
  }
  #edit-icon {
    stroke: var(--airbag-dark);
    background-color: var(--airbag-primary);
    margin: 0 0 0 15px;
    cursor: pointer;
    padding: 3px;
    border-radius: 35px;
    width: 35px;
    height: 35px;
    text-align: center;
    vertical-align: middle;
    line-height: 35px;
  }
  .info-table {
    background-color: var(--airbag-white);
    border-radius: 6px;
    margin-left: 1px !important;
    margin-right: 1px !important;
    margin-bottom: 12px;
    text-align: center;
    padding: 6px;
    .table-title {
      font-weight: bold;
      margin: 12px 0 5px 0;
    }
    .tableHeader {
      display: inline-flex;
      justify-content: center;
    }
    .subtitle {
      padding-top: 18px;
      margin: 0;
      color: var(--airbag-grey);
      font-size: 13.5px;
    }
    .tableValue {
      font-weight: bold;
      margin-top: 6px;
      margin-bottom: 12px;
    }
  }
  .no-data {
    text-align: center;
    margin: 12px 0 24px 0;
    font-weight: 700;
    .error-icon {
      width: 100px;
      stroke: var(--airbag-grey-light);
    }
    p {
      margin-top: 0;
    }
  }
  .pos-index {
    margin-right: 5px;
    color: var(--airbag-grey-light);
  }
  #leaders-table {
    margin: 0 0 12px 0;
  }
  #drivers-table-wrapper {
    margin-left: -4px !important;
    margin-right: -4px !important;
    #drivers-table {
      margin: 0 0 12px 0;
      .driver-row {
        height: 48px;
        &:hover {
          background-color: var(--airbag-background);
          a {
            border-bottom: 1px solid var(--airbag-primary);
          }
        }
        a {
          color: inherit;
        }
        .pos-index {
          margin-right: 5px;
          color: var(--airbag-grey-light);
        }
      }
      .last-seen {
        font-weight: bold;
        font-size: 12px;
        width: 70px;
        display: inline-block;
        &.bad {
          color: var(--airbag-error);
        }
        &.regular {
          color: var(--airbag-attention);
        }
        &.good {
          color: var(--airbag-successful);
        }
        &.none {
          color: var(--airbag-grey);
        }
      }
      .pills-container {
        text-align: center;
        .pill {
          display: inline-block;
          text-align: center;
          width: 90px;
          padding: 3px 10px;
          border-radius: 42px;
          font-weight: 600;
          font-size: 10px;
          &.red-s {
            background-color: var(--airbag-error-light);
            color: var(--airbag-error);
          }
          &.yellow-s {
            background-color: var(--airbag-attention-light);
            color: var(--airbag-attention);
          }
          &.green-s {
            background-color: var(--airbag-successful-light);
            color: var(--airbag-successful);
          }
          &.grey-s {
            background-color: var(--airbag-grey-light);
            color: var(--airbag-dark-light);
          }
        }
      }
    }
    #switch-table {
      margin-bottom: 12px;
      th,
      td {
        padding-right: 0;
        padding-left: 0;
      }
      .switch-row {
        height: 48px;
        &:hover {
          background-color: var(--airbag-background);
        }
        .switch-container {
          text-align: center;
          .hide-switch {
            display: none;
          }
          .disabled-switch {
            opacity: 0.5;
          }
          input[type='checkbox'] {
            height: 0;
            width: 0;
            visibility: hidden;
          }
          label {
            cursor: pointer;
            text-indent: -9999px;
            width: 46px;
            height: 21px;
            background: var(--airbag-grey);
            display: block;
            border-radius: 100px;
            position: relative;
            margin: 0 auto;
          }
          label:after {
            content: '';
            position: absolute;
            top: 3px;
            left: 3px;
            width: 15px;
            height: 15px;
            border-radius: 90px;
            transition: 0.3s;
          }
          .on:after {
            background: var(--airbag-successful-light);
          }
          .off:after {
            background: var(--airbag-grey-light);
          }
          input:checked + label {
            background: var(--airbag-successful);
          }
          input:checked + label:after {
            left: calc(100% - 3px);
            transform: translateX(-100%);
          }
        }
      }
    }
  }
  .indicator-title {
    margin: 0 6px 12px 6px;
    padding: 6px 0;
    font-size: 12px;
    border-bottom: 1px solid var(--airbag-grey-light);
  }
}
