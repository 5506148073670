div#_vehicle-metrics_ {
  #no-scores {
    text-align: center;
    svg {
      color: var(--airbag-primary);
    }
  }
  .metrics-table {
    background-color: var(--airbag-white);
    padding: 15px 0;
    border-radius: 6px;
    text-align: center;
    margin: 0 0 30px 0;
    .table-title {
      font-weight: bold;
      margin: 0;
      @media only screen and (max-width: 992px) {
        margin: 10px 0 5px 0;
      }
      @media only screen and (min-width: 993px) {
        margin: 0;
      }
    }
    .wrapp {
      @media only screen and (max-width: 992px) {
        margin: 10px 0;
      }
      .stat-title {
        color: var(--airbag-grey);
        @media only screen and (max-width: 992px) {
          margin: 15px 0 0 0;
        }
        @media only screen and (min-width: 993px) {
          font-size: 13px;
          margin: 30px 0 0 0;
        }
        &.less-margin {
          @media only screen and (max-width: 992px) {
            margin: 15px 0 0 0;
          }
          @media only screen and (min-width: 993px) {
            margin: 20px 0 0 0;
          }
        }
      }
      .stat-value {
        color: var(--airbag-dark);
        margin: 0;
        margin-top: 2px;
        font-weight: bold;
        @media only screen and (max-width: 600px) {
          font-size: 17px;
        }
        @media only screen and (min-width: 601px) {
          font-size: 18px;
        }
        &.st-red {
          color: var(--airbag-error);
        }
        &.st-yellow {
          color: var(--airbag-attention);
        }
        &.st-green {
          color: var(--airbag-successful);
        }
        &.st-na {
          color: var(--airbag-background);
        }
        span {
          font-weight: normal;
          font-size: 14px;
          color: var(--airbag-grey);
        }
      }
    }
  }
}
