#_driver-activity_ {
  #filters {
    padding: 0;
    margin: 18px 0 12px 0;
    #day-label {
      text-align: center;
      font-weight: bold;
      margin: 5px 0 12px 0;
      .day-box {
        margin: 0 10px;
        svg {
          margin: 0 5px;
        }
        &.active {
          padding: 0 5px 5px 5px;
          border-bottom: 2px solid var(--airbag-primary);
        }
      }
      .option {
        cursor: pointer;
        font-size: 12px;
        color: var(--airbag-grey);
        &.not-valid {
          color: var(--airbag-grey-light);
          cursor: auto;
        }
      }
    }
    .topContent {
      display: flex;
      flex-direction: row;
      margin-top: 18px;
      .input-field {
        margin: 0 6px 0 0;
        input {
          width: 100%;
          max-width: 200px;
          margin: 0 0 0 auto !important;
          background-color: var(--airbag-white);
        }
      }
      #debug {
        width: 100%;
        max-width: 120px;
        margin: 14px 6px 0 0;
        position: relative;
        label {
          font-size: 10px;
        }
      }
      #heartbeats-history {
        margin: 0 0 0 auto;
      }
    }
  }
}
