div#_edit-user-group_ {
  #main-t {
    text-align: center;
    margin-bottom: 25px;
    color: var(--airbag-primary);
  }
  form {
    .big-label {
      margin-bottom: 0;
      p {
        margin-bottom: 20px;
        font-weight: bold;
        color: var(--airbag-grey);
      }
    }
    input {
      background-color: var(--airbag-background);
    }
  }
  #label-user {
    color: var(--airbag-grey);
    font-size: 13px;
    font-weight: bold;
    background-color: rgb(245, 244, 244);
    padding: 11px 10px;
    padding-left: 15px;
    height: 2.5rem;
    border-radius: 40px;
    margin-top: 7px;
    margin-bottom: 5px;
    width: fit-content;
    margin-right: 10px;
  }
  .circle {
    margin-right: 10px;
  }
  .delete {
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
  }
  #users {
    margin-top: -20px;
  }
  #users-container {
    display: flex;
    flex-wrap: wrap;
  }
}
