$border-color: var(--airbag-grey-light);
$background-color: var(--airbag-background);

div#_form_ {
  svg {
    color: var(--airbag-primary);
  }
  background-color: $background-color;
  border-radius: 8px;
  padding: 8px;

  .form-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h5 {
      margin: 0;
    }

    .pdf-download {
      display: flex;
      gap: 8px;
      align-items: center;
      p {
        padding: 0%;
        margin: 0;
      }
      &:hover {
        cursor: pointer;
        p {
          color: var(--airbag-primary);
        }
        svg {
          stroke: var(--airbag-primary);
        }
      }
    }
  }
  .form-description {
    font-size: small;
    color: #5b5b5b;
  }
  .question {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 6px;
  }
  .description {
    color: #464646;
    margin: 3px 0 6px 0;
    font-size: 12px;
  }
  .answer {
    font-size: 14px;
    margin: 0;
  }

  .obj-value {
    margin: 0;
    font-size: 14px;
  }
}

.driver-info-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: $background-color;

  .basic-info,
  .other-info,
  .job-info {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 8px;
  }

  .driver-name {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .basic-info {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }

  .other-info {
    display: flex;
    flex-direction: row;

    flex-wrap: wrap;
  }

  .driver-entry {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    margin: 8px 0px;
    width: 45%;

    span:first-child {
      font-weight: 600;
    }
  }

  .job-info {
    div {
      margin: 8px 0px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: space-between;

      span:first-child {
        font-weight: 600;
      }
    }
  }

  @media screen and (min-width: 769px) {
    flex-direction: row;
    justify-content: space-around;

    .job-info {
      padding: 0px 16px;

      div {
        flex-direction: row;
      }
    }
    .driver-info,
    .other-info,
    .job-info {
      width: 30%;
    }
    .driver-entry {
      padding: 0px 16px;
    }
  }
}

.question-item {
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--airbag-grey-lighter);

  transition: all 0.3s ease;

  .form-photos {
    background-color: #f2f2f2;
    border-radius: 6px;
    padding: 4px 10px 14px 10px;
    margin-top: 10px;
    height: fit-content;
    .arrow {
      background-color: #ffffff;
      padding: 6px;
      font-size: 30px;
      border-radius: 20px;
      width: 30px;
      height: 30px;
    }
    .form-photo {
      width: 100%;
      max-width: 250px;
      user-select: none;
    }
  }
  .photo-grid {
    display: none;
  }
}

.vehicle-info {
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: $background-color;

  h5 {
    margin-bottom: 8px;
    font-size: 20px;
  }

  .vehicle-details {
    display: flex;
    flex-wrap: wrap;

    .basic-vehicle-info,
    .other-vehicle-info {
      display: flex;
      flex-direction: column;
      gap: 8px;

      div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 8px;
        border-radius: 4px;
        background-color: $background-color;

        span {
          &:first-child {
            font-weight: bold;
            color: #555;
          }
          &:last-child {
            color: #333;
          }
        }
      }
    }

    .vehicle-measurements {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      gap: 8px;

      div {
        display: flex;
        margin-bottom: 8px;
        justify-content: space-between;
        padding: 0px;
        border-radius: 4px;
        background-color: $background-color;
      }
    }

    .vehicle-insurance {
      padding: 16px;
      border: 1px solid $border-color;
      border-radius: 8px;
      background-color: $background-color;

      div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 8px;
        border-radius: 4px;

        span {
          &:first-child {
            font-weight: bold;
            color: #555;
          }
          &:last-child {
            color: #333;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .vehicle-info {
      flex-direction: row;
    }

    .vehicle-details {
      flex-direction: column;
      gap: 16px;

      .basic-vehicle-info,
      .other-vehicle-info {
        flex: 1 1 auto;
      }
    }
  }

  @media screen and (min-width: 769px) {
    .vehicle-info {
      flex-direction: column;
    }

    .vehicle-details {
      flex-direction: row;
      gap: 16px;
      justify-content: space-around;

      .basic-vehicle-info,
      .other-vehicle-info,
      .vehicle-insurance {
        padding: 16px;
        border: 1px solid $border-color;
        border-radius: 8px;
        background-color: $background-color;
        width: 30%;

        div {
          word-wrap: break-word;
        }
      }
    }
  }
}

.pdf-view {
  max-width: 73%;
  background-color: white !important;
  font-family: Arial, sans-serif;
  line-height: 1;
  margin: 0;
  padding: 10px;

  h5 {
    font-size: large;
  }

  .form-header {
    h5 {
      margin: 0;
    }
    p,
    svg {
      display: none;
      padding: 0%;
      margin: 0;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: var(--airbag-primary);
      }
    }
  }
  .form-description {
    font-size: 18px;
    color: #5b5b5b;
  }
  .question {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 6px;
  }

  .description {
    color: #464646;
    margin: 3px 0 6px 0;
    font-size: 12px;
  }
  .answer {
    font-size: 14px;
    margin: 0;
  }
  .obj-value {
    margin: 0;
    font-size: 14px;
  }
  .vehicle-info {
    font-size: 13px;
    border: none;
    gap: 0;
    padding: 0;
    margin: 0;
    background-color: white;
  }

  .vehicle-details {
    width: 100%;
    flex-direction: column;
    gap: 16px;
    background-color: white;

    .basic-vehicle-info,
    .other-vehicle-info,
    .vehicle-insurance {
      flex-direction: row;
      background-color: white;
      border: none;
      padding: 0;
      div {
        background-color: white;
        justify-content: start;
      }
      .vehicle-measurements {
        flex-direction: row;
      }
    }

    .vehicle-insurance {
      display: flex;
      gap: 16px;
      div {
        padding: 0;
      }
    }
  }

  .driver-info-container {
    font-size: 13px;
    display: flex;
    flex-direction: column;
    border: none;
    padding: 0;
    background-color: white;

    .basic-info,
    .driver-info,
    .other-info,
    .job-info {
      width: 100%;
      border: none;
      padding: 0;
      gap: 8px;
    }

    .driver-name {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .basic-info {
      display: flex;
      flex-direction: row;
      gap: 24px;
    }

    .other-info {
      display: flex;
      flex-direction: row;
      gap: 24px;

      flex-wrap: wrap;
    }

    .driver-entry {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 0;
      padding: 0;
      gap: 0;
      width: fit-content;

      span:first-child {
        font-weight: 600;
      }
    }

    .job-info {
      display: flex;
      gap: 24px;
      @media screen and (min-width: 769px) {
        padding: 0;
      }
      div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0;

        span:first-child {
          font-weight: 600;
        }
      }
    }
  }
  .question-item {
    padding-bottom: 1rem;
    border-bottom: none;

    .form-photos {
      display: none;
    }
    .photo-grid {
      display: flex;
      gap: 16px;
      img {
        width: 100%;
        max-width: 250px;
        user-select: none;
        max-height: 250px;
      }
    }
  }
}
