div#_filters-trips_ {
  margin: 12px auto 0 auto;
  select,
  input {
    display: inline-block;
    width: 100%;
    background-color: var(--airbag-white);
  }
  .item-container {
    display: inline-block;
    @media only screen and (max-width: 600px) {
      margin: 0 0 24px 0;
      width: 100%;
    }
    @media only screen and (min-width: 601px) {
      margin: 0 12px 0 0;
      width: 175px;
    }
  }
  #filters-box {
    @media only screen and (max-width: 992px) {
      margin-top: 36px;
    }
    @media only screen and (min-width: 993px) {
      margin-top: 30px;
    }
  }
}
