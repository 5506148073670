div#_all-drivers-table_ {
	#drivers-table {
    margin-bottom: 12px;
    background-color: #fff;
    height: 250px;
    #drivers-select {
      background-color: #f2f2f2;
      width: 100%;
      max-width: 250px;
    }
    .driver-row {
      &:hover {
        background-color: var(--airbag-background);
        a {
          border-bottom: 1px solid var(--airbag-primary);
        }
      }
      a {
        color: inherit;
      }
      .pos-index {
        margin-right: 5px;
        color: var(--airbag-grey-light);
      }
    }
  }
}