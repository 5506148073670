#check {
  margin-left: auto;
}

#label-user {
  color: #4b4b4b;
  font-size: 13px;
  font-weight: bold;
  background-color: rgb(245, 244, 244);
  padding: 11px 10px;
  padding-left: 15px;
  height: 2.5rem;
  border-radius: 40px;
  margin-top: 7px;
  margin-bottom: 5px;
  width: fit-content;
  margin-right: 10px;
}

.circle {
  margin-right: 10px;
}

.delete {
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

#users {
  margin-top: -20px;
}

#users-container {
  display: flex;
  flex-wrap: wrap;
}
