div#_risk-by-location-charts_ {
  background: #fff;
  border-radius: 6px;
  .risk-by-location {
    height: 400px;
    margin-bottom: 60px !important;
  }
  #filters {
      margin-top: 6px !important;
      margin-right: 6px !important;
      width: 100%;
      max-width: 200px;
      float: right;
      select {
        background-color: #f8f8f8;
      }
  }
}
