div#_filters-vehicles_ {
  margin: 25px auto 0 auto;
  select,
  input {
    display: inline-block;
    width: 100%;
    background-color: var(--airbag-white);
  }
  .item-container {
    display: inline-block;
    @media only screen and (max-width: 600px) {
      margin: 15px 10px 15px 0;
      width: 100%;
    }
    @media only screen and (min-width: 601px) {
      margin: 0 10px 0 0;
      width: 175px;
    }
  }
  #query-clear {
    position: absolute;
    margin: 14px 0 0 -20px;
    color: var(--airbag-dark-light);
    cursor: pointer;
  }
  #add-vehicle-btn {
    float: right;
    #add-vehicle-icon {
      stroke: var(--airbag-dark);
      stroke-width: 25px;
      margin: -4px 0 -4px 10px;
      width: 16px;
    }
  }
}
