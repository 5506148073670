div#_add-vehicle_ {
  #main-t {
    text-align: center;
    margin-bottom: 25px;
    color: #f4d23a;
  }
  form {
    .big-label {
      margin-bottom: 0;
      p {
        margin-bottom: 20px;
        font-weight: bold;
        color: #464646;
      }
    }
    .react-datepicker-wrapper {
      width: 100%;
    }
    input,
    select {
      background-color: #f2f2f2;
    }
    textarea {
      background-color: #f2f2f2;
      resize: none;
      border: none;
      height: fit-content;
      min-height: 100px;
    }
  }
}
