div#_vehicle-trips_ {
  margin: 0;
  #trips-content {
    a {
      color: inherit;
    }
    .driverRow {
      margin: 0 0 12px 26px;
    }
    #driverIcon {
      color: var(--airbag-grey-light);
      margin-right: 10px;
    }
    .trip-score {
      h5 {
        text-align: center;
        margin-top: 27px;
        @media only screen and (min-width: 601px) {
          border-right: 1px solid var(--airbag-grey-light);
        }
        &.bad {
          color: var(--airbag-error);
        }
        &.regular {
          color: var(--airbag-attention);
        }
        &.good {
          color: var(--airbag-successful);
        }
      }
    }
    .trip-points {
      list-style: none;
      margin: 0;
      padding: 10px 0;
      li {
        position: relative;
        padding: 5px 0;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        &:after {
          position: absolute;
          left: 0;
          top: 0;
          content: '';
          border-left: 2px dotted var(--airbag-grey-light);
          margin-left: 9px;
          margin: 8px 0 0 9px;
          height: 56%;
        }
        &:first-of-type:after {
          top: 52%;
        }
        &:last-of-type:after {
          top: -50%;
        }
        svg {
          margin-right: 10px;
          width: 20px;
          &.small-icon {
            font-size: 9px;
            color: var(--airbag-grey-light);
          }
          &.big-icon {
            font-size: 14px;
            color: var(--airbag-primary);
          }
        }
        span {
          color: var(--airbag-dark-primary-variant);
          margin-left: 10px;
          padding-left: 10px;
          border-left: 1px solid var(--airbag-grey-light);
        }
      }
    }
    .icon {
      margin-right: 5px;
      color: var(--airbag-grey-light);
    }
    #preloader-wrapper {
      margin: 20px 0;
      #preloader {
        margin: 15px auto 0 auto;
        .spinner-layer {
          border-color: var(--airbag-primary);
        }
      }
    }
  }
}
