div#_home-indicators_ {
  margin: 0;
  .no-margin-fix {
    margin-left: auto;
    margin-right: auto;
  }
  .kpi {
    text-align: center;
    margin: 0 0 12px 0;
    background-color: var(--airbag-white);
    border-radius: 6px;
    padding: 12px 6px;
    min-height: 70px;
    @media only screen and (min-width: 993px) {
      height: 73px;
      padding: 18px 6px 6px 6px;
    }
    .value {
      margin: 0 0 2px 0;
      font-weight: bold;
      font-size: 15px;
      &.bad {
        color: var(--airbag-error);
      }
      &.regular {
        color: var(--airbag-attention);
      }
      &.good {
        color: var(--airbag-successful);
      }
      .kpi-icon {
        color: var(--airbag-grey-light);
        stroke: var(--airbag-dark);
        margin-right: 10px;
        width: 25px;
        &.custom {
          margin-bottom: -4px;
          width: 20px;
        }
      }
    }
    label {
      display: block;
      font-size: 12px;
      margin: 0;
      color: var(--airbag-grey);
    }
  }
  #trips-chart {
    height: 158px;
    margin: 0 0 12px 0;
    #trips-chart-container {
      margin-top: 33px;
      height: 115px;
    }
  }
}
