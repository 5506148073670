div#_manuals_ {
  #main-title {
    margin-bottom: 10px;
    h5 {
      font-weight: bold;
      margin-bottom: 0;
      font-size: 18px;
    }
    p {
      margin: 5px 0 0 0;
      width: 100%;
      text-align: justify;
      max-width: 600px;
      font-size: 14px;
    }
  }
  #manuals-content {
    .manual-container {
      .box {
        background-color: var(--airbag-white);
        border-radius: 6px;
        padding: 6px;
        margin: 0 0 12px 0;
        cursor: pointer;
        box-sizing: border-box;
        &:hover {
          .manual-icon {
            stroke: var(--airbag-primary) !important;
          }
        }
        .top-info {
          margin: 0 0 3px 0;
          svg {
            color: var(--airbag-gery);
          }
          span {
            float: right;
            font-size: 12px;
          }
        }
        .icon-container {
          text-align: center;
          .manual-icon {
            width: 30px;
            margin-bottom: 6px;
            stroke: var(--airbag-grey);
            max-height: 30px;
          }
        }
        .content {
          text-align: center;
          margin: 0 0 3px 0;
          .title {
            font-weight: bold;
            margin: 0;
            @media only screen and (max-width: 464px) {
              font-size: 12px;
            }
          }
          .description {
            margin: 3px 0;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            @media only screen and (max-width: 464px) {
              height: 100px;
            }
            @media only screen and (min-width: 465px) {
              height: 64px;
            }
            @media only screen and (min-width: 1201px) {
              height: 50px;
            }
          }
        }
      }
    }
  }
  #manuals-courtain {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: var(--airbag-dark);
    svg {
      right: 0;
      position: absolute;
      stroke: var(--airbag-dark);
      background-color: var(--airbag-primary);
      border-radius: 50px;
      width: 24px;
      padding: 4px;
      margin: 24px 24px 0 0;
      cursor: pointer;
    }
    #courtain-content {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      iframe {
        height: 480px;
        width: 90%;
        max-width: 720px;
      }
    }
  }
}
