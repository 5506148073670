$light-gray: #f2f2f2;

#no-data {
  text-align: center;
  margin-top: 25px;
  font-weight: 700;
  #error-icon {
    width: 100px;
    stroke: var(--airbag-grey-light);
  }
  p {
    margin-top: 0;
  }
}

#forms-table {
  a {
    color: inherit;
  }
  .pos-index {
    margin-right: 5px;
    color: var(--airbag-grey-light);
  }
  margin-top: 18px;
  .form-row {
    &:hover {
      background-color: var(--airbag-background);
      a {
        border-bottom: 1px solid var(--airbag-primary);
      }
    }
    a {
      color: inherit;
    }
    .pos-index {
      margin-right: 5px;
      color: var(--airbag-grey-light);
    }
  }
}
