div#_loader_ {
  width: 100%;
  height: 100vh;
  .preloader-wrapper {
    margin: 0 auto;
    .spinner-layer {
      border-color: #464646;
    }
  }
}
