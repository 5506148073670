#not-found {
  height: 100vh;
  padding: 10px;
  #not-found-content {
    margin: 0 auto;
    text-align: center;
    svg {
      font-size: 100px;
      color: #464646;
    }
    h3 {
      font-family: var(--airbag-font);
      font-weight: bold;
      color: #464646;
    }
    h4 {
      font-family: var(--airbag-font);
      margin: 25px 0 42px 0;
      color: #464646;
    }
  }
}
