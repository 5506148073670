@font-face {
  font-family: 'PP Object Sans';
  src: url('/assets/fonts/PPObjectSans-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'PP Object Sans';
  src: url('/assets/fonts/PPObjectSans-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'PP Object Sans';
  src: url('/assets/fonts/PPObjectSans-Light.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'PP Object Sans';
  src: url('/assets/fonts/PPObjectSans-Slanted.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'PP Object Sans';
  src: url('/assets/fonts/PPObjectSans-LightSlanted.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: PP Object Sans;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.bold {
  font-weight: bold;
}
.no-margin {
  margin: 0;
}
.pointer {
  cursor: pointer;
}
.toast {
  font-family: PP Object Sans;
}
.success-toast {
  border-radius: 6px;
  background-color: var(--airbag-successful-light);
  color: #000;
  border-left: 6px solid var(--airbag-successful);
}
.error-toast {
  border-radius: 6px;
  background-color: var(--airbag-error-light);
  color: #000;
  border-left: 6px solid var(--airbag-error);
}
.info-toast {
  border-radius: 6px;
  background-color: var(--airbag-attention-light);
  color: #000;
  border-left: 6px solid var(--airbag-primary);
}
input:focus + label {
  color: #9e9e9e !important;
}
textarea:focus,
input:focus,
select:focus {
  border: 1px solid var(--airbag-primary) !important;
  -webkit-box-shadow: 0px 0px 0px 3px rgba(252, 245, 207, 1) !important;
  -moz-box-shadow: 0px 0px 0px 3px rgba(252, 245, 207, 1) !important;
  box-shadow: 0px 0px 0px 3px rgba(252, 245, 207, 1) !important;
}
input,
textarea,
select {
  font-family: PP Object Sans;
  outline: none !important;
  margin: 0 !important;
}
input {
  border-radius: 40px !important;
  border: 1px solid transparent !important;
  padding: 0 8px !important;
  box-sizing: border-box !important;
}
textarea {
  border-radius: 15px !important;
  border-bottom: 0 !important;
  padding: 10px !important;
}
select {
  cursor: pointer;
  border-radius: 40px !important;
  border: 1px solid transparent !important;
  box-sizing: border-box !important;
}
label {
  font-family: PP Object Sans;
  margin-left: 10px;
  margin-top: -4px;
  font-size: 14px;
}
.input-field {
  label {
    transform: translateY(-14px) scale(0.8);
    transform-origin: 0 0;
  }
}
.switch label input[type='checkbox']:checked + .lever {
  background-color: #adadad;
}
.switch label input[type='checkbox']:checked + .lever:after {
  background-color: #5a5a5a;
}
input[type='checkbox'].filled-in:checked + span:not(.lever):after {
  border: 0;
  background-color: var(--airbag-primary);
}
input[type='checkbox'].filled-in:checked + span:not(.lever):before {
  border-right-color: var(--airbag-dark);
  border-bottom-color: var(--airbag-dark);
}
input[type='checkbox'].filled-in:not(:checked) + span:not(.lever):after {
  border: 1px solid #5a5a5a;
}
input[type='radio'].with-gap:checked + span:before {
  border: 2px solid var(--airbag-primary);
}
input[type='radio'].with-gap:checked + span:after {
  background-color: var(--airbag-primary);
  border: 2px solid var(--airbag-primary);
}
// React datepicker
.react-datepicker-popper {
  .react-datepicker {
    border: 0;
    font-family: PP Object Sans;
    -webkit-box-shadow: 0px 3px 12px -2px rgba(128, 128, 128, 1);
    -moz-box-shadow: 0px 3px 12px -2px rgba(128, 128, 128, 1);
    box-shadow: 0px 3px 12px -2px rgba(128, 128, 128, 1);
    button {
      &:focus {
        background-color: #fff;
      }
    }
    .react-datepicker__triangle {
      border-bottom-color: #fff;
      &:before {
        border-bottom-color: #fff;
      }
    }
    .react-datepicker__header {
      border-bottom: 0;
      background-color: #fff;
    }
    .react-datepicker__day-name {
      font-weight: bold;
    }
    .react-datepicker__header__dropdown {
      margin: 5px 0;
    }
    .react-datepicker__navigation--next {
      margin-right: 10px;
    }
    .react-datepicker__time-container {
      border-left: 1px solid #ececec;
      .react-datepicker__time {
        .react-datepicker__time-box {
          .react-datepicker__time-list {
            .react-datepicker__time-list-item {
              height: auto;
              padding: 10px;
            }
          }
        }
      }
    }
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__month--selected,
    .react-datepicker__day--selected,
    .react-datepicker__time-list-item--selected {
      background-color: #333 !important;
      color: var(--airbag-primary) !important;
    }
  }
}
.filters {
  margin-top: 30px;
  .box {
    height: 50px;
    background-color: #dadada;
  }
}
.box-container {
  margin: 18px 0;
  background-color: #fff;
  border-radius: 6px;
  .dark {
    background-color: #fff;
  }
  .row {
    margin-right: 0;
    margin-left: 0;
    .col {
      margin-right: 0;
      margin-left: 0;
    }
  }
  .box-top-title {
    background-color: #fff;
    color: #000;
    margin: 0;
    padding: 9px 3px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    p {
      font-family: PP Object Sans;
      font-weight: bold;
      font-size: 13px;
      span {
        &.pointer {
          cursor: pointer !important;
        }
      }
    }
    button {
      font-size: 10px;
      padding: 6px 12px 4px 12px;
      margin-top: 10px;
    }
    .box-icon {
      width: 20px;
      margin-right: 5px;
      color: #989898;
    }
    .sort-arrow {
      margin-left: 5px;
      color: #bdbdbd;
    }
  }
  .box-content {
    overflow: overlay;
    max-height: 312px;
    @-moz-document url-prefix() {
      overflow: scroll;
    }
    &.min-height {
      min-height: 100px;
    }
  }
  .box-data {
    margin: 0;
    font-family: PP Object Sans;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -o-transition: 0.2s;
    -ms-transition: 0.2s;
    &:hover {
      background-color: #f6f6f6;
    }
    border-bottom: 1px solid #f4f4f4;
    a {
      color: inherit;
    }
    p {
      font-size: 13px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      margin: 0;
    }
    .col {
      padding-top: 12px;
      padding-bottom: 12px;
    }
    .status {
      min-width: 100px;
      padding: 2px 10px;
      border-radius: 42px;
      font-weight: 600;
      font-size: 10px;
      &.active {
        background-color: var(--airbag-successful-light);
        color: var(--airbag-successful);
      }
      &.inactive {
        background-color: var(--airbag-error-light);
        color: var(--airbag-error);
      }
      &.red-s {
        background-color: var(--airbag-error-light);
        color: var(--airbag-error);
      }
      &.yellow-s {
        background-color: var(--airbag-attention-light);
        color: var(--airbag-attention);
      }
      &.green-s {
        background-color: var(--airbag-successful-light);
        color: var(--airbag-successful);
      }
      svg {
        font-size: 8px;
        margin-right: 5px;
      }
    }
    .box-data-cta {
      text-align: center;
      margin-top: -5px;
      line-height: 40px;
      span {
        cursor: pointer;
        border: 1px solid var(--airbag-primary);
        font-weight: 600;
        color: var(--airbag-primary);
        padding: 2px 8px;
        border-radius: 40px;
        svg {
          margin-right: 10px;
        }
      }
    }
  }
}
// Custom search inputs (with suggestions box)
.suggestions-box {
  position: absolute;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  border-radius: 6px;
  z-index: 10;
  margin-top: 5px;
  background-color: #fff;
  border: 2px solid var(--airbag-primary);
  -webkit-box-shadow: 0px 6px 15px -4px rgba(222, 222, 222, 1);
  -moz-box-shadow: 0px 6px 15px -4px rgba(222, 222, 222, 1);
  box-shadow: 0px 6px 15px -4px rgba(222, 222, 222, 1);
  .suggestion {
    padding: 15px 10px;
    margin: 0;
    cursor: pointer;
  }
  .loader {
    text-align: center;
    .preloader-wrapper {
      margin: 10px auto 8px auto;
      .spinner-layer {
        border-color: #464646;
      }
    }
  }
}
.selected {
  background-color: #f2f2f2;
  padding: 11px 10px;
  height: 3rem;
  border-radius: 40px;
  margin: 0;
  svg {
    float: right;
    margin: 4px 5px 0 0;
    cursor: pointer;
  }
}
.airbag-table-container {
  overflow-x: scroll;
  border-radius: 6px;
  background-color: #fff;
  .airbag-top {
    padding: 6px 17px;
  }
  .airbag-table {
    display: table;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    .pos-index {
      margin-right: 5px;
      color: var(--airbag-grey-light);
    }
    thead {
      color: #000;
      font-size: 13px;
      tr {
        border: 0;
        .sort-arrow {
          margin-left: 5px;
          color: #bdbdbd;
        }
        th {
          border-radius: 0;
          padding: 12px 17px;
        }
        .table-head-icon {
          stroke: #464646;
        }
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid #f2f2f2;
        td {
          border-radius: 0;
          padding: 12px 14px;
          font-size: 13px;
          .table-icon-indicator {
            width: 50px;
            stroke: #8d8d8d;
          }
        }
        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}
.row {
  .col {
    padding: 0 6px;
  }
}
.dark-color-text {
  color: var(--airbag-dark);
}
