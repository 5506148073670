div#_driver-reports_ {
  display: flex;
  justify-content: center;
  width: 100%;

  #loader-container {
    margin-top: 25px;
  }

  #no-data {
    text-align: center;
    margin-top: 25px;
    font-weight: 700;

    #error-icon {
      width: 100px;
      stroke: var(--airbag-grey-light);
    }

    p {
      margin-top: 0;
    }
  }

  .filters {
    margin-bottom: 30px;

    .dp-wrap {
      .search-filters {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        @media only screen and (max-width: 700px) {
          flex-direction: column;
        }

        @media only screen and (min-width: 700px) {
          flex-direction: row;
        }

        .item-container {
          width: 240px !important;
          margin-right: 16px;
        }

        .react-datepicker-wrapper {
          @media only screen and (max-width: 600px) {
            width: 100%;
            margin-bottom: 10px;
          }
        }

        .year-container {
          position: relative;

          #yearInput {
            width: 100% !important;
            height: 3rem !important;
            background-color: #ffffff;
            padding: 11px 10px;
            border-radius: 40px;
            margin-top: 10px;
            margin-bottom: 12px;
            text-align: center;
          }

          #date-clear {
            position: absolute;
            top: 15px;
            right: 35px;
            color: var(--airbag-dark);
            cursor: pointer;
          }
        }

        .button-container {
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: 300px;
        }
      }
    }

    .calendar-container {
      display: flex;
      flex-flow: column;
      flex-wrap: wrap;
      max-width: 500px;
      margin-top: 18px;

      .fake-input {
        background-color: #f2f2f2;
        padding: 11px 10px;
        height: 3rem;
        border-radius: 40px;
        margin-top: 2px;
        margin-bottom: 12px;
        text-align: center;
        position: relative;

        #date-clear {
          position: absolute;
          top: 30%;
          right: 40px;
          color: var(--airbag-dark);
          cursor: pointer;
        }
      }

      .react-calendar-heatmap-weekday-labels {
        margin-left: 20px !important;
      }

      .react-calendar-heatmap .color-empty {
        fill: #d4d4d4;
        outline: none;
      }

      .react-calendar-heatmap .color-filled {
        fill: var(--airbag-primary) !important;
        border-radius: 100%;
      }

      .react-calendar-heatmap .color-filled:focus {
        outline: none;
        stroke: var(--airbag-dark);
      }
    }
  }
  #activities-table {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
  #map-container {
    position: sticky;
    top: 80px;
    margin-bottom: 12px;
    text-align: center;
    p {
      font-weight: bold;
    }
    #map-message {
      background-color: #fff;
      padding: 3px 6px;
      border-radius: 6px;
      #act-tag-focus {
        font-size: 21px;
        background-color: var(--airbag-primary);
        padding: 8px;
        width: 30px;
        height: 30px;
        border-radius: 50px;
        margin: 0 9px;
      }
    }
    .calendar-container {
      margin-top: 12px;
    }
  }
}