div#_company-notifications_ {
	#main-t {
    text-align: center;
    margin: 9px 0;
    color: var(--airbag-primary);
  }
	#top-notif-options {
		border-bottom: 1px solid var(--airbag-grey-light);
		padding-bottom: 12px;
		height: 36px;
		margin-bottom: 6px;
		span {
			font-size: 12px;
			font-weight: bold;
			padding: 0 6px 3px 6px;
		}
		.right-cta {
			margin-left: 15px;
			float: right;
			cursor: pointer;
			&.active {
				border-bottom: 1px solid var(--airbag-primary);
			}
		}
	}
	#notif-container {
		background-color: #fff;
		padding: 12px;
		height: calc(100vh - 300px);
		overflow-y: scroll;
		#no-notifications {
			text-align: center;
			#no-notif-icon {
				font-size: 2em;
				margin: 30px 0 9px 0;
				color: var(--airbag-grey);
			}
			p {
				margin: 0;
			}
		}
		.notif-link {
			cursor: pointer;
			p {
				color: #000;
			}
			svg {
				color: #000;
			}
		}
		.notif-row {
			border-bottom: 1px solid var(--airbag-grey-lighter);
			.row {
				margin: 6px 0;
				border-radius: 18px;
				padding: 6px 3px;
				&.unread {
					background-color: var(--airbag-background);
				}
				p {
					margin: 0;
				}
				.n-icon {
					width: 33px;
					height: 33px;
					background-color: var(--airbag-grey-lighter);
					padding: 8px;
					border-radius: 50%;
					margin: 9px 0 -1px 0;
				}
				.unread-dot {
					color: var(--airbag-error);
					display: block;
					margin: 12px auto 0 auto;
					font-size: 9px;
				}
				.n-title {
					font-weight: bold;
					margin-bottom: 6px;
				}
				.n-description {
					margin-bottom: 6px;
					font-size: 14px;
				}
				.n-bottom {
					.n-date {
						color: var(--airbag-grey);
						font-size: 12px;
					}
				}
				.cta-icon {
					float: right;
					margin-right: 12px;
					&.delete-i {
						cursor: pointer;
						color: var(--airbag-grey-light);
						&:hover {
							color: var(--airbag-error);
						}
					}
					&.read-i {
						color: var(--airbag-dark-light);
					}
					&.unread {
						color: var(--airbag-grey-light);
						&:hover {
							color: var(--airbag-dark-light);
							cursor: pointer;
						}
					}
				}
			}
		}
	}
}