#_driver-activations_ {
	max-height: 370px;
	overflow: auto;
	.date-row {
		font-weight: bold;
		margin: 30px 0 0 0;
	}
	.activation-row {
		margin-top: 9px;
		.custom-col {
			@media only screen and (max-width: 600px) {
				text-align: center;
			}
			@media only screen and (min-width: 601px) {
				display: table-cell;
			}
		}
		.date-col {
			@media only screen and (max-width: 600px) {
				margin-bottom: 6px;
			}
			@media only screen and (min-width: 601px) {
				margin: 0 6px 0 0;
				text-align: center;
			}
			.act-date {
				margin: 0 12px 0 0;
				font-weight: bold;
				font-size: 12px;
			}
		}
		.content-col {
			.act-title {
				@media only screen and (max-width: 600px) {
					margin: 0 auto 6px auto;
				}
				@media only screen and (min-width: 601px) {
					margin: 0 0 6px 0;
				}
				.status {
					padding: 2px 10px;
					border-radius: 42px;
					font-weight: 600;
					font-size: 11px;
					&.red-s {
						background-color: var(--airbag-error-light);
						color: var(--airbag-error);
					}
					&.green-s {
						background-color: var(--airbag-successful-light);
						color: var(--airbag-successful);
					}
					&.grey-s {
						background-color: var(--airbag-grey-light);
						color: var(--airbag-dark-light);
					}
				}
			}
			.act-body {
				margin-right: 10px;
				font-size: 13px;
			}
		}
	}
}