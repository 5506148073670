#_company-vehicles_ {
  #loader-container {
    margin-top: 25px;
  }
  #no-data {
    text-align: center;
    margin-top: 25px;
    font-weight: 700;
    #error-icon {
      width: 100px;
      stroke: var(--airbag-grey-light);
    }
    p {
      margin-top: 0;
    }
  }
  #vehicles-table {
    margin-top: 18px;
    .vehicle-row {
      &:hover {
        background-color: var(--airbag-background);
        a {
          border-bottom: 1px solid var(--airbag-primary);
        }
      }
      a {
        color: inherit;
      }
      .pos-index {
        margin-right: 5px;
        color: var(--airbag-grey-light);
      }
    }
  }
}
