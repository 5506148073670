div#_edit-vehicle_ {
  #main-t {
    text-align: center;
    margin-bottom: 25px;
    color: var(--airbag-primary);
  }
  form {
    .big-label {
      margin-bottom: 0;
      p {
        margin-bottom: 20px;
        font-weight: bold;
        color: var(--airbag-grey-light);
      }
    }
    .react-datepicker-wrapper {
      width: 100%;
    }
    input,
    select {
      background-color: var(--airbag-background);
    }
    textarea {
      background-color: var(--airbag-background);
      resize: none;
      border: none;
      height: fit-content;
      min-height: 100px;
    }
    #note {
      font-size: x-small;
      font-weight: bold;
      color: var(--airbag-grey);
    }
  }
}
