.weather-table {
  background-color: var(--airbag-white);
  border-radius: 6px;
  margin-left: 1px !important;
  margin-right: 1px !important;
  margin-bottom: 12px;
  text-align: center;
  .table-title {
    font-weight: bold;
    margin-top: 20px !important;
    @media only screen and (max-width: 992px) {
      margin: 10px 0 5px 0;
    }
    @media only screen and (min-width: 993px) {
      margin: 0;
    }
  }
  .tableHeader {
    display: inline-flex;
    justify-content: center;
  }
  .noInfo {
    font-weight: bold;
    font-size: 15px;
    padding-top: 20px;
  }
}
