div#_driver-trips_ {
  margin: 6px 0 12px 0;
  display: flex;
  flex-flow: column;
  #trips-table {
    margin-bottom: 25px;
    a {
      color: inherit;
    }
    #trip-scores-exp {
      .score-item {
        margin: 0 3px;
        cursor: pointer;
      }
    }
    .trip-score {
      font-weight: bold;
      text-align: center;
      .bad {
        color: var(--airbag-error);
      }
      .regular {
        color: var(--airbag-attention);
      }
      .good {
        color: var(--airbag-successful);
      }
      .overall-score {
        margin: 0 6px;
        display: inline-block;
        width: 25px;
      }
      .scores-icons {
        margin-left: 6px;
        .score-item {
          margin: 0 3px;
        }
      }
    }
    .trip-row {
      &:hover {
        background-color: var(--airbag-background);
        a {
          border-bottom: 1px solid var(--airbag-primary);
        }
      }
      a {
        color: inherit;
      }
    }
  }
}
