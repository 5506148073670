.H_ib_body {
  background-color: var(--airbag-dark-light);
  text-align: center;
  color: #fff;
  border-radius: 10px;
  width: 250px;
  border-top: 2px solid var(--airbag-primary);
  .H_btn {
    &:hover {
      .H_icon {
        fill: #ccc;
      }
    }
  }
  .H_icon {
    fill: #fff;
  }
  .H_ib_content {
    font-family: var(--airbag-font);
    margin-top: 22px;
    .H_tib_content {
      width: auto;
      color: #fff;
      .H_rdo_title {
        background-color: inherit;
      }
      .H_tib_time {
        color: #fff;
      }
    }
  }
  b,
  p {
    color: white;
  }
  .last-seen {
    font-size: 13px;
    font-weight: bold;
    span {
      color: #dadada;
    }
  }
  .heartbeat-data {
    font-size: 13px;
    color: #fff;
    font-weight: bold;
    span {
      color: #dadada;
      font-weight: normal;
    }
  }
  #cat-btn {
    background-color: var(--airbag-primary);
    font-family: var(--airbag-font);
    font-weight: bold;
    color: var(--airbag-dark-light);
    margin: 5px auto 0 auto;
    padding: 5px 12px;
    border-radius: 50px;
    display: inline-block;
  }
  .type {
    font-weight: bold;
    margin: 0;
  }
  .date {
    margin-top: 0;
    font-size: 14px;
    margin-bottom: 15px;
  }
  .log-description {
    margin-top: 0;
  }
  .coordinates {
    margin-top: -10px;
    font-size: 11px;
    font-style: italic;
    margin-bottom: -10px;
  }
  .sanctions-box {
    margin: 0 0 8px 0;
    p {
      margin: 0;
    }
    .s-title {
      font-weight: bold;
      font-size: 14px;
    }
    .s-duration {
      font-size: 13px;
    }
    .s-times {
      color: var(--airbag-primary);
      font-size: 12px;
    }
  }
}
/** Style for the info bubble tail */
.H_ib.H_ib_top .H_ib_tail::after {
  border-color: var(--airbag-dark-light) transparent;
}
