#_company-drivers_ {
  #loader-container {
    margin-top: 25px;
  }
  #no-data {
    text-align: center;
    margin-top: 25px;
    font-weight: 700;
    #error-icon {
      width: 100px;
      stroke: var(--airbag-grey-light);
    }
    p {
      margin-top: 0;
    }
  }
  .no-padding-left {
    padding-left: 0;
  }
  #drivers-table {
    margin-top: 18px;
    .info-tip {
      cursor: pointer;
      color: var(--airbag-primary);
      margin-left: 5px;
      font-size: 12px;
    }
    .driver-row {
      height: 48px;
      &:hover {
        background-color: var(--airbag-background);
        a {
          border-bottom: 1px solid var(--airbag-primary);
        }
      }
      a {
        color: inherit;
      }
      .pos-index {
        margin-right: 5px;
        color: var(--airbag-grey-light);
      }
    }
    .eye-cta {
      cursor: pointer;
      color: var(--airbag-white);
      padding: 3px;
      stroke-width: 2px;
      border-radius: 35px;
      width: 18px;
      height: 18px;
      margin-right: 6px;
      text-align: center;
      vertical-align: middle;
      line-height: 25px;
      background-color: var(--airbag-primary);
      stroke: var(--airbag-dark-light);
      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
        stroke: var(--airbag-grey);
        background-color: var(--airbag-grey-light);
      }
    }
    .last-seen {
      font-weight: bold;
      font-size: 12px;
      width: 70px;
      display: inline-block;
      &.bad {
        color: var(--airbag-error);
      }
      &.regular {
        color: var(--airbag-attention);
      }
      &.good {
        color: var(--airbag-successful);
      }
      &.none {
        color: var(--airbag-grey);
      }
    }
    .ranking-pos {
      font-size: 12px;
      font-weight: bold;
      text-align: center;
    }
    .score-span {
      font-weight: bold;
      &.bad {
        color: var(--airbag-error);
      }
      &.regular {
        color: var(--airbag-attention);
      }
      &.good {
        color: var(--airbag-successful);
      }
      &.none {
        color: var(--airbag-grey);
      }
    }
    .pills-container {
      text-align: center;
      .pill {
        display: inline-block;
        text-align: center;
        width: 90px;
        padding: 3px 10px;
        border-radius: 42px;
        font-weight: 600;
        font-size: 10px;
        &.red-s {
          background-color: var(--airbag-error-light);
          color: var(--airbag-error);
        }
        &.yellow-s {
          background-color: var(--airbag-attention-light);
          color: var(--airbag-attention);
        }
        &.green-s {
          background-color: var(--airbag-successful-light);
          color: var(--airbag-successful);
        }
        &.grey-s {
          background-color: var(--airbag-grey-light);
          color: var(--airbag-dark-light);
        }
      }
    }
  }
  #switch-table {
    margin-top: 18px;
    th,
    td {
      padding-right: 0;
      padding-left: 0;
    }
    .switch-row {
      height: 48px;
      &:hover {
        background-color: var(--airbag-background);
      }
      .switch-container {
        text-align: center;
        .hide-switch {
          display: none;
        }
        .disabled-switch {
          opacity: 0.5;
        }
        input[type='checkbox'] {
          height: 0;
          width: 0;
          visibility: hidden;
        }
        label {
          cursor: pointer;
          text-indent: -9999px;
          width: 46px;
          height: 21px;
          background: var(--airbag-grey);
          display: block;
          border-radius: 100px;
          position: relative;
          margin: 0 auto;
        }
        label:after {
          content: '';
          position: absolute;
          top: 3px;
          left: 3px;
          width: 15px;
          height: 15px;
          border-radius: 90px;
          transition: 0.3s;
        }
        .on:after {
          background: var(--airbag-successful-light);
        }
        .off:after {
          background: var(--airbag-grey-light);
        }
        input:checked + label {
          background: var(--airbag-successful);
        }
        input:checked + label:after {
          left: calc(100% - 3px);
          transform: translateX(-100%);
        }
      }
    }
  }
}
