div#_activity-map_ {
  #map {
    width: 100%;
    height: 500px;
    margin-bottom: 0;
  }
  #loading-routes {
    position: relative;
    top: -95%;
    left: 10px;
    color: #464646;
    font-weight: bold;
    text-align: center;
    display: inline-flex;
    white-space: nowrap;
    border-radius: 40px;
    text-align: center;
    background-color: #fff;
    padding: 5px 5px;
    padding-left: 20px;
    height: 60px;
    border-radius: 50px;
    padding-left: 0;
  }
  #trips-table {
    margin: 25px 0;
    th,
    td {
      text-align: center;
    }
    .trip-row {
      &:hover {
        background-color: var(--airbag-background);
        a {
          border-bottom: 1px solid var(--airbag-primary);
        }
      }
      a {
        color: inherit;
      }
    }
    .map-marker {
      margin-right: 6px;
    }
    .eye-icon {
      color: var(--airbag-primary);
      margin-right: 15px;
      cursor: pointer;
    }
    .trip-score {
      font-weight: bold;
      text-align: center;
      &.bad {
        color: var(--airbag-error);
      }
      &.regular {
        color: var(--airbag-attention);
      }
      &.good {
        color: var(--airbag-successful);
      }
    }
    .trip-sum {
      td {
        font-size: 14px;
        text-align: center;
        font-weight: bold;
      }
    }
  }
}
