div#_filters-drivers_ {
  margin: 12px auto 0 auto;
  select,
  input {
    display: inline-block;
    width: 100%;
    background-color: var(--airbag-white);
  }
  .item-container {
    display: inline-block;
    @media only screen and (max-width: 600px) {
      margin: 0 0 24px 0;
      width: 100%;
    }
    @media only screen and (min-width: 601px) {
      margin: 0 12px 0 0;
      width: 175px;
    }
  }
  #query-clear {
    position: absolute;
    margin: 14px 0 0 -20px;
    color: #2f2f2f;
    cursor: pointer;
  }
  #add-driver-btn {
    @media only screen and (max-width: 400px) {
      width: 100%;
    }
    @media only screen and (min-width: 401px) {
      float: right;
    }
    @media only screen and (min-width: 993px) {
      margin-top: 6px;
    }
    #add-driver-icon {
      stroke: var(--airbag-dark);
      stroke-width: 25px;
      margin: -4px 0 -4px 10px;
      width: 16px;
    }
  }
  #filters-btn {
    @media only screen and (max-width: 400px) {
      width: 100%;
      margin-bottom: 18px;
    }
    @media only screen and (min-width: 401px) and (max-width: 600px) {
      margin-bottom: -20px;
    }
    @media only screen and (min-width: 601px) {
      margin-bottom: -10px;
    }
    &.filters-opened {
      background-color: var(--airbag-white);
      border-color: var(--airbag-white);
      color: var(--airbag-primary);
      #filters-btn-icon {
        stroke: var(--airbag-primary);
      }
    }
    #filters-btn-icon {
      stroke: var(--airbag-dark);
      margin: -6px 0 -9px 8px;
      width: 25px;
    }
  }
  #filters-box {
    @media only screen and (max-width: 992px) {
      margin-top: 36px;
    }
    @media only screen and (min-width: 993px) {
      margin-top: 30px;
    }
  }
}
