$add: var(--airbag-successful-light);
$subtract: var(--airbag-error-light);
$border-color: #f2f2f2;
.airbag-coins-container {
  border-radius: 6px;
  background-color: #fff;

  .coin-history-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .coin-history-button {
      margin: 8px 0px 16px 0px;
    }
  }

  .coin-history-header {
    display: grid;
    grid-template-columns: 0.7fr 1fr 1fr 1fr 1fr;
    font-weight: bold;
    padding: 8px 8px;
    justify-items: center;
    width: 100%;
  }

  .coin-history {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: 570px;
    overflow: auto;
    width: 100%;
    scrollbar-width: thin;
  }

  .coin-history-header,
  .coin-history {
    @media screen and (min-width: 768px) {
      width: 70%;
    }
  }

  .driver-coins-item {
    margin: 0px 4px;
    display: grid;
    grid-template-columns: 0.7fr 1fr 1fr 1fr 1fr;
    align-items: center;
    padding: 12px 4px;
    position: relative;
    border-bottom: 1px solid $border-color;

    &.has-description {
      cursor: pointer;
    }

    .icon-and-number {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      user-select: none;

      svg {
        margin: 0;
        font-size: 10px;
        &.add {
          color: green;
        }
        &.subtract {
          color: red;
        }
      }
      span {
        font-size: 16px;
        margin: 0 4px;
      }
    }

    .coin-item {
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    .reason,
    .date {
      font-size: 2.5vw;

      @media only screen and (min-width: 600px) {
        font-size: 14px;
      }
    }

    .description {
      display: none;

      &.open {
        display: block;
        grid-column: 1/-1;
        font-size: x-small;
        margin-top: 8px;
        width: 98%;

        @media only screen and (min-width: 600px) {
          font-size: 11px;
        }

        @media only screen and (min-width: 768px) {
          font-size: 12px;
        }
      }
    }

    .description-caret {
      position: absolute;
      font-size: 16px;
      top: 0px;
      left: 8px;

      @media only screen and (min-width: 600px) {
        top: 13px;
      }

      svg {
        margin: 0;
      }
    }
  }

  .no-history {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
}
