div._message-courtain_ {
  position: relative;
  height: calc(100vh - 210px);
  .courtain-content {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    svg {
      font-size: 5rem;
      margin-bottom: 15px;
      color: var(--airbag-primary);
    }
    h5 {
      font-weight: bold;
    }
    p {
      margin-bottom: 25px;
    }
  }
}
