div#_active-drivers-table_ {
  #drivers-table {
    margin-bottom: 12px;
    background-color: #fff;
    height: 250px;
    #drivers-select {
      background-color: #f2f2f2;
      width: 100%;
      max-width: 250px;
    }
    .last-seen-color {
      font-weight: bold;
      text-align: center;
      &.bad {
        color: var(--airbag-error);
      }
      &.regular {
        color: var(--airbag-attention);
      }
      &.good {
        color: var(--airbag-successful);
      }
      &.other {
        color: var(--airbag-grey-light);
      }
    }
    .driver-row {
      &:hover {
        background-color: var(--airbag-background);
        a {
          border-bottom: 1px solid var(--airbag-primary);
        }
      }
      a {
        color: inherit;
      }
      .pos-index {
        margin-right: 5px;
        color: var(--airbag-grey-light);
      }
    }
  }
}
