div#_filters-trips_user {
  .dp-wrap {
    margin: 0 0 15px 0;
    text-align: right;
    @media only screen and (max-width: 600px) {
      width: 100%;
      display: flex;
      flex-flow: column;
    }
    @media only screen and (min-width: 600px) {
      width: 500px;
      display: flex;
      flex-flow: row;
      align-items: flex-end;
    }

    select,
    input {
      display: inline-block;
      width: 100%;
      background-color: var(--airbag-white);
    }
    .item-container {
      display: inline-block;
      @media only screen and (max-width: 600px) {
        margin: 0 0 12px 0;
        width: 100%;
      }
      @media only screen and (min-width: 601px) {
        margin: 0 12px 0 0;
        width: 175px;
      }
    }
    #filters-box {
      @media only screen and (max-width: 992px) {
        margin-top: 36px;
      }
      @media only screen and (min-width: 993px) {
        margin-top: 30px;
      }
    }
    .date-picker-container {
      position: relative;
      .react-datepicker-wrapper {
        @media only screen and (max-width: 600px) {
          width: 100%;
          margin-bottom: 10px;
        }
        input {
          background-color: var(--airbag-white);
        }
      }
      #date-clear {
        position: absolute;
        top: 15px;
        right: 12px;
        color: var(--airbag-dark);
        cursor: pointer;
      }
    }
  }
}
