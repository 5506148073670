div#_error_ {
  text-align: center;
  margin-top: 50px;
  svg {
    font-size: 4em;
    color: var(--airbag-primary);
  }
  h5 {
    font-family: var(--airbag-font);
    font-weight: bold;
    color: #464646;
  }
  p {
    font-family: var(--airbag-font);
    font-weight: 600;
    width: 100%;
    max-width: 500px;
    margin: 0 auto 25px auto;
    color: #464646;
  }
}
