div#_edit-flags_ {
  #main-t {
    text-align: center;
    margin-bottom: 25px;
    color: var(--airbag-primary);
  }
  form {
    input,
    select {
      background-color: var(--airbag-background);
    }
  }
}
