#_header-section_ {
  #main-title {
    margin-bottom: 10px;
    h5 {
      text-align: left;
      font-weight: bold;
      font-size: 18px;
    }
    p {
      .pointer {
        cursor: pointer;
        text-decoration: underline;
        text-decoration-color: var(--airbag-primary);
      }
      margin: 5px 0 0 0;
      width: 100%;
      text-align: justify;
      max-width: 600px;
      font-size: 14px;
      font-weight: 400;
    }
    #more-info {
      visibility: visible !important;
      cursor: pointer;
      color: var(--airbag-primary);
      margin-left: 5px;
      font-size: 12px;
    }
  }
}
