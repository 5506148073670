div#_driver-quick-view_ {
  #name {
    text-align: center;
    h5 {
      font-weight: bold;
      margin-bottom: 0;
      span {
        color: #000;
        svg {
          font-size: 14px;
          color: var(--airbag-primary);
          vertical-align: baseline;
        }
      }
    }
    #last-seen {
      font-size: 12px;
      margin: 6px 0 0 0;
      span {
        color: #737373;
      }
    }
    #driver-status {
      margin-top: 12px;
      .status {
        min-width: 100px;
        padding: 3px 10px;
        border-radius: 42px;
        font-weight: 600;
        font-size: 10px;
        &.red-s {
          background-color: var(--airbag-error-light);
          color: var(--airbag-error);
        }
        &.yellow-s {
          background-color: var(--airbag-attention-light);
          color: var(--airbag-attention);
        }
        &.green-s {
          background-color: var(--airbag-successful-light);
          color: var(--airbag-successful);
        }
        &.grey-s {
          background-color: var(--airbag-grey-light);
          color: var(--airbag-grey);
        }
      }
    }
    #driver-status {
      margin: 10px 0 0 0;
      .status {
        min-width: 100px;
        padding: 2px 10px;
        border-radius: 42px;
        font-weight: 600;
        font-size: 12px;
        svg {
          font-size: 8px;
          margin-right: 5px;
        }
        &.red-s {
          background-color: var(--airbag-error-light);
          color: var(--airbag-error);
        }
        &.yellow-s {
          background-color: var(--airbag-attention-light);
          color: var(--airbag-attention);
        }
        &.green-s {
          background-color: var(--airbag-successful-light);
          color: var(--airbag-successful);
        }
        &.status-inactive {
          background-color: var(--airbag-grey-light);
          color: var(--airbag-grey);
        }
      }
    }
  }
  .big-label {
    margin-bottom: 0;
    &.center-mobile {
      @media only screen and (max-width: 600px) {
        text-align: center;
      }
    }
    p {
      margin-bottom: 20px;
      font-weight: bold;
      color: #464646;
    }
    .big-label-indicator {
      font-weight: normal;
      font-size: 11px;
      color: #737373;
    }
  }
  .fake-input {
    background-color: #f2f2f2;
    padding: 11px 10px;
    height: 3rem;
    border-radius: 40px;
    margin-top: 0;
  }
  #heartbeats-wrapper {
    margin-top: 15px;
  }
  #notifications-list {
    .notification-row {
      margin-top: 15px;
      @media only screen and (max-width: 600px) {
        text-align: center;
        margin-top: 18px;
      }
      .status-wrapper {
        width: 120px;
        @media only screen and (max-width: 600px) {
          margin: 0 auto 6px auto;
        }
        @media only screen and (min-width: 601px) {
          margin: 0 0 3px 0;
        }
        .status {
          padding: 2px 10px;
          border-radius: 42px;
          font-weight: 600;
          font-size: 11px;
          &.red-s {
            background-color: var(--airbag-error-light);
            color: var(--airbag-error);
          }
          &.green-s {
            background-color: var(--airbag-successful-light);
            color: var(--airbag-successful);
          }
          &.yellow-s {
            background-color: var(--airbag-attention-light);
            color: var(--airbag-attention);
          }
          .status-icon {
            margin-right: 12px;
            font-size: 10px;
          }
        }
      }
      .notif-date {
        margin-right: 10px;
        font-size: 14px;
      }
      .notif-source {
        font-weight: bold;
        color: #333;
      }
    }
  }
  #_hearbeats-loading_ {
    width: 100%;
    .preloader-wrapper {
      margin: 0 auto;
      &.small {
        width: 32px;
        height: 32px;
      }
      .spinner-layer {
        border-color: #464646;
      }
    }
  }
}
