#company-profile {
  .input-field > label.active {
    top: -5px;
  }
  .select-wrapper + label {
    top: -31px;
  }
  .divider {
    margin: 10px 0 35px 0;
  }
  input,
  select {
    font-family: var(--airbag-font);
    border: 0;
    background-color: var(--airbag-white);
    padding: 0 5px;
    box-sizing: border-box;
  }
  form {
    font-family: var(--airbag-font);
    margin: 30px 0 30px 0;
    .sub {
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
  .color {
    width: 100px;
    height: 100px;
    float: left;
  }
  .sep {
    width: 100%;
    float: left;
  }
  .input-field {
    p {
      margin: 0;
    }
  }
}
