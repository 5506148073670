$active: var(--airbag-primary);
$default: var(--airbag-grey-light);
$inactive: var(--airbag-white);
$background-color: var(--airbag-background);

div#_driver-game_ {
  background-color: $background-color;
  #level-row {
    color: #000;
  }
  #no-data {
    margin-top: 25px;
    font-weight: 700;
    #error-icon {
      width: 100px;
      stroke: var(--airbag-grey-light);
    }
    p {
      margin-top: 0;
    }
  }
}

.day {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  @media screen and (min-width: 768px) {
    flex-direction: column;
    gap: 16px;
  }

  > h4 {
    margin: 8px 0 0 0;
  }
}

.streak-container {
  display: flex;
  flex-direction: row;
  padding: 8px;
  justify-content: space-evenly;
  align-items: center;
}

.streak-day {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;

  @media screen and (min-width: 768px) {
    width: 4vw;
    height: 4vw;
    max-width: 60px;
    max-height: 60px;
  }

  &.inactive {
    border: 2px solid $active;
    background-color: $inactive;

    &:hover {
      background-color: $inactive;
      box-shadow: 0 0 5px $active, 0 0 25px $active, 0 0 50px $active,
        0 0 200px $active;
    }

    > svg {
      color: $active;
    }
  }

  &.active {
    border: 2px solid $active;
    background-color: $active;

    &:hover {
      background-color: $active;
      box-shadow: 0 0 5px $active, 0 0 25px $active, 0 0 25px $active,
        0 0 200px $active;
    }

    > svg {
      color: $inactive;
    }
  }

  &.default {
    border: 2px solid $default;
    background-color: $default;

    &:hover {
      background-color: $default;
      box-shadow: 0 0 5px $default, 0 0 25px $default, 0 0 25px $default,
        0 0 200px $default;
    }

    > svg {
      color: var(--airbag-grey);

      stroke: red;
    }
  }
}

.streak-week-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  > p {
    color: var(--airbag-grey);
    margin-left: auto;
  }
}

.streak-week {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-left: 8px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.streak-legend {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  align-self: center;
  width: 50%;

  @media screen and (min-width: 768px) {
    width: 25%;
  }

  > p {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    margin: 8px;
  }

  > p:nth-child(3),
  p:nth-child(4) {
    font-weight: 100;
    color: var(--airbag-grey);
  }

  > p > span {
    font-weight: 600;
  }
}

.streak-icon {
  font-size: 56px;

  &.on {
    color: $active;
  }

  &.off {
    color: var(--airbag-grey-light);
  }
}

.level-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin: 8px 8px;
  gap: 8px;

  .progress-bar-container {
    width: 50vw;
    @media screen and (min-width: 768px) {
      width: 30vw;
    }
  }

  .progress-bar {
    background-color: var(--airbag-grey-light);
    height: 20px;
    border-radius: 10px 10px;
    overflow: hidden;
    width: 100%;
  }

  .progress-bar-fill {
    background-color: var(--airbag-primary);
    height: 20px;
    border-radius: 10px 10px;
    text-align: center;
    transition: width 0.3s ease;
  }

  .progress-bar-values {
    display: flex;
    justify-content: space-between;
    font-size: 10px;

    @media screen and (min-width: 768px) {
      font-size: 12px;
    }
  }

  .level-icon-container {
    background-color: var(--airbag-primary);
    height: 80px;
    width: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .level-icon {
      font-size: 64px;
      color: var(--airbag-white);
      transform: rotate(90deg);
    }

    .level-number {
      position: absolute;
      font-size: 40px;
      font-weight: 600;
      user-select: none;
    }
  }

  h3 {
    margin: 8px 0px;
  }

  span {
    font-weight: 600;
  }

  > div:nth-child(3) {
    width: 100%;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    text-align: end;
    margin-right: 32px;

    @media screen and (min-width: 768px) {
      width: auto;
      margin-right: 0px;
    }
  }
}

.no-level {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  > p {
    margin: 0;
    padding: 0;
  }
}
