div#_driver-profile_ {
  width: 100%;
  margin: 0 auto;
  @media only screen and (max-width: 600px) {
    margin-top: 25px;
  }
  @media only screen and (min-width: 601px) {
    margin-top: 25px;
  }
  #wa-msg-container {
    width: 100%;
    max-width: 300px;
    margin: 0 0 0 auto;
    #whatsapp-container {
      .select-message {
        background-color: var(--airbag-grey-lighter);
        height: 30px;
      }
    }
    #wa-btn-container {
      margin-top: 6px;
      @media only screen and (max-width: 601px) {
        display: inline-block;
        margin-left: 12px;
      }
      #wa-btn {
        padding: 6px 10px 5px 10px;
        @media only screen and (min-width: 601px) {
          width: 100%;
        }
        #whatsLogo {
          margin: 0 0 -3px 6px;
          width: 12px;
          height: 12px;
        }
      }
    }
  }
  #top {
    margin-bottom: 12px;
    #profile {
      background-color: var(--airbag-white);
      border-radius: 6px;
      padding: 15px 18px 10px 18px;
      @media only screen and (min-width: 1201px) {
        min-height: 300px;
      }
      .first-info-container {
        display: flex;
        justify-content: space-between;
        #left-container {
          display: inline-block;
          #driver-name {
            font-weight: bold;
            font-size: 18px;
            margin: 0 0 0 0;
            #battery-perc {
              margin-left: 10px;
              font-weight: normal;
              font-size: 11px;
              svg {
                margin-right: 5px;
                &.full {
                  color: var(--airbag-successful);
                }
                &.med {
                  color: var(--airbag-attention);
                }
                &.low {
                  color: var(--airbag-error);
                }
              }
            }
          }
          #last-seen {
            font-size: 12px;
            margin: 0;
            #last-seen-time {
              font-weight: bold;
              &.good {
                color: var(--airbag-successful);
              }
              &.regular {
                color: var(--airbag-attention);
              }
              &.bad {
                color: var(--airbag-error);
              }
            }
            @-moz-keyframes spin {
              100% {
                -moz-transform: rotate(360deg);
              }
            }
            @-webkit-keyframes spin {
              100% {
                -webkit-transform: rotate(360deg);
              }
            }
            @keyframes spin {
              100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
              }
            }
          }
        }
        #right-container {
          @media only screen and (max-width: 600px) {
            margin-top: 6px;
            height: 100%;
          }
          @media only screen and (min-width: 601px) {
            float: right;
          }
          .whatsapp-container {
            .select-message {
              background-color: var(--airbag-grey-lighter);
            }
          }
          #wa-btn-container {
            margin-top: 6px;
            @media only screen and (max-width: 601px) {
              display: inline-block;
              margin-left: 12px;
            }
            #wa-btn {
              padding: 6px 10px 5px 10px;
              @media only screen and (min-width: 601px) {
                width: 100%;
              }
              #whatsLogo {
                margin: 0 0 -3px 6px;
                width: 12px;
                height: 12px;
              }
            }
          }
          .status {
            padding: 3px 18px;
            font-weight: bold;
            border-radius: 50px;
            font-size: 12px;
            vertical-align: middle;
            &.red-s {
              background-color: var(--airbag-error-light);
              color: var(--airbag-error);
              stroke: var(--airbag-error);
            }
            &.yellow-s {
              background-color: var(--airbag-attention-light);
              color: var(--airbag-attention);
              stroke: var(--airbag-attention);
            }
            &.green-s {
              background-color: var(--airbag-successful-light);
              color: var(--airbag-successful);
              stroke: var(--airbag-successful);
            }
            &.grey-s {
              background-color: var(--airbag-grey-light);
              color: var(--airbag-dark-light);
            }
            .preloader-wrapper {
              width: 10px;
              height: 10px;
              margin-right: 10px;
              .spinner-layer {
                border-color: inherit;
              }
            }
          }
          .switch-container {
            text-align: center;
            @media only screen and (max-width: 600px) {
              margin: 0 0 -8px 6px;
              display: inline-block;
            }
            @media only screen and (min-width: 601px) {
              float: right;
              margin: 0 0 0 6px;
            }
            &.hide-switch {
              opacity: 0;
            }
            &.disabled-switch {
              opacity: 0.5;
            }
            input[type='checkbox'] {
              height: 0;
              width: 0;
              visibility: hidden;
            }
            label {
              cursor: pointer;
              text-indent: -9999px;
              width: 50px;
              height: 23px;
              background: var(--airbag-grey);
              display: block;
              border-radius: 100px;
              position: relative;
              margin: 0 auto;
            }
            label:after {
              content: '';
              position: absolute;
              top: 3px;
              left: 3px;
              width: 17px;
              height: 17px;
              border-radius: 90px;
              transition: 0.3s;
            }
            .on:after {
              background: var(--airbag-successful-light);
            }
            .off:after {
              background: var(--airbag-grey-light);
            }
            input:checked + label {
              background: var(--airbag-successful);
            }
            input:checked + label:after {
              left: calc(100% - 3px);
              transform: translateX(-100%);
            }
          }
        }
      }
      #profile-info {
        margin: 20px 0 0 0;
        .info-content {
          label {
            margin-left: 35px;
          }
          p {
            margin-top: 0;
            margin-bottom: 10px;
            font-size: 14px;
            a {
              color: inherit;
            }
            .indicator {
              color: var(--airbag-grey-light);
              margin-right: 10px;
              width: 25px;
            }
          }
        }
      }
      #heartbeats-wrapper {
        margin-top: 10px;
        width: 100%;
        @media only screen and (max-width: 992px) {
          max-width: 600px;
        }
        @media only screen and (min-width: 1201px) and (max-width: 1699px) {
          max-width: 600px;
        }
      }
    }
    #map-container {
      #map {
        width: 100%;
        @media only screen and (max-width: 1200px) {
          height: 300px;
          margin: 12px auto 0 auto;
        }
        @media only screen and (min-width: 1201px) {
          height: 300px;
          border-radius: 6px;
          margin: 0;
        }
        canvas {
          border-radius: 6px;
        }
      }
    }
  }
  #_hearbeats-loading_ {
    width: 100%;
    .preloader-wrapper {
      margin: 0 auto;
      &.small {
        width: 32px;
        height: 32px;
      }
      .spinner-layer {
        border-color: var(--airbag-grey-light);
      }
    }
  }
}
